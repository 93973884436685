html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
a{
  text-decoration: none !important;
}
.card-title{
  color: #223645;
  font-size: 22px;
}
.product-desc-area .event-text h2{
    font-size: 22px;
    margin: 0;
    line-height: 1.2;
    letter-spacing: 0px;
    margin-bottom: 15px;
}
.product-desc-area .event-text p{
  margin-bottom: 5px;
}
.product-desc-area .event-text ul{
  margin-left: 35px;
}
.product-desc-area .event-text ul li{
  list-style: disc;
}