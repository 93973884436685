/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
  --thm-font: "Poppins", sans-serif;
  --thm-reey-font: "reeyregular";
  --thm-gray: #787780;
  --thm-gray-rgb: 120, 119, 128;
  --thm-primary: #e8604c;
  --thm-primary-rgb: 232, 96, 76;
  --thm-black: #313041;
  --thm-black-rgb: 49, 48, 65;
  --thm-base: #ffffff;
  --thm-base-rgb: 255, 255, 255;
  --thm-clr-extra: #e3e3e3;
  --thm-border-radius: 8px;
  --thm-letter-spacing: -0.02em;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body {
  font-family: var(--thm-font);
  color: var(--thm-gray);
  font-size: 18px;
  line-height: 34px;
  font-weight: 400;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--thm-gray);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--thm-black);
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: var(--thm-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  background-color: var(--thm-primary);
  color: var(--thm-base);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 30px 8px;
  border-radius: 8px;
  transition: all 0.3s linear;
  overflow: hidden;
  letter-spacing: 0.1em;
  z-index: 1;
}

.thm-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--thm-black);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  opacity: 1;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.thm-btn:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.thm-btn:hover {
  color: var(--thm-base);
}

.section-title {
  margin-top: 5px;
  margin-bottom: 47px;
}

.section-title__tagline {
  display: block;
  color: var(--thm-primary);
  font-size: 20px;
  align-items: center;
  line-height: 30px;
  font-weight: 400;
  font-family: var(--thm-reey-font);
}

.section-title__title {
  margin: 0;
  font-weight: 700;
  color: var(--thm-black);
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show > .btn-light.dropdown-toggle {
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select > .dropdown-toggle {
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
  background-color: transparent !important;
}

.bootstrap-select .dropdown-menu.inner {
  border-bottom-left-radius: var(--thm-border-radius);
  border-bottom-right-radius: var(--thm-border-radius);
}

.bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--thm-primary);
  color: #fff;
}

.bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
  color: #ffffff;
  background: var(--thm-black);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu > li > a:hover {
  background: var(--thm-primary);
  color: #fff;
  cursor: pointer;
}

.bootstrap-select .dropdown-menu > li:last-child > a {
  border-bottom-left-radius: var(--thm-border-radius);
  border-bottom-right-radius: var(--thm-border-radius);
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/*--------------------------------------------------------------
# Navigations One
--------------------------------------------------------------*/
.main-header {
  position: relative;
  display: block;
  background: var(--thm-base);
}

.main-header__top {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: var(--thm-black);
}

.main-header__top-inner {
  position: relative;
  display: block;
}

.main-header__top-left {
  position: relative;
  display: block;
  float: left;
  padding: 14.5px 0px;
}

.main-header__top-address {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px;
}

.main-header__top-address li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-address li + li {
  margin-left: 20px;
}

.main-header__top-address li .icon {
  display: flex;
  align-items: center;
}

.main-header__top-address li .icon span {
  font-size: 15px;
  color: var(--thm-primary);
}

.main-header__top-address li .text {
  margin-left: 10px;
}

.main-header__top-address li .text a {
  font-size: 13px;
  font-weight: 500;
  color: #a9a8b6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-address li .text a:hover {
  color: var(--thm-base);
}

.main-header__top-right {
  position: relative;
  display: block;
  float: right;
}

.main-header__top-right-inner {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-right-social {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 14.5px 0;
  margin-right: 40px;
}

.main-header__top-right-social a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--thm-base);
  font-size: 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-right-social a:hover {
  color: var(--thm-primary);
}

.main-header__top-right-social a + a {
  margin-left: 20px;
}

.main-header__top-right-btn {
  padding: 5px 40px 5px;
  border-radius: 0;
}

.main-header__top-right-btn:after {
  background-color: var(--thm-base);
}

.main-header__top-right-btn:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.main-header__top-right-btn:hover {
  color: var(--thm-black);
}

.main-menu {
  position: relative;
  padding: 0 0;
  z-index: 91;
}

.main-menu-wrapper {
  position: relative;
  display: block;
}

.main-menu-wrapper-inner {
  position: relative;
  display: block;
}

.main-menu-wrapper__left {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.main-menu-wrapper__logo {
  position: relative;
  float: left;
  padding-top: 24px;
  padding-bottom: 31.5px;
  /* margin-right: 80px; */
}
.main-menu-wrapper__logo img{
  width: 137px;
}

.main-menu-wrapper__main-menu {
  position: relative;
  display: block;
  float: left;
}

.main-menu-wrapper__right {
  position: relative;
  padding: 25px 0;
  align-items: center;
  justify-content: center;
}

.main-menu__search,
.main-menu__user {
  height: 50px;
  width: 50px;
  background-color: #faf5ee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  color: var(--thm-black);
  transition: all 500ms ease;
}

.main-menu__search:hover {
  background-color: var(--thm-primary);
  color: var(--thm-base);
}

.main-menu__user {
  margin-left: 10px;
}

.main-menu__user:hover {
  background-color: var(--thm-primary);
  color: var(--thm-base);
}

.stricky-header.main-menu {
  padding: 0 0;
  background-color: var(--thm-base);
}

.main-menu .main-menu__list,
.main-menu .main-menu__list ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  display: none;
}

@media (min-width: 1200px) {
  .main-menu .main-menu__list,
  .main-menu .main-menu__list ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list ul {
    display: flex;
  }
}

.main-menu .main-menu__list > li,
.stricky-header .main-menu__list > li {
  padding-top: 36px;
  padding-bottom: 36px;
  position: relative;
}

.main-menu .main-menu__list > li + li,
.stricky-header .main-menu__list > li + li {
  margin-left: 54px;
}

.main-menu .main-menu__list > li > a,
.stricky-header .main-menu__list > li > a {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--thm-gray);
  position: relative;
  transition: all 500ms ease;
}

.main-menu .main-menu__list > li > a::before,
.stricky-header .main-menu__list > li > a::before {
  content: "";
  width: 100%;
  height: 3px;
  border-radius: 0px;
  background-color: var(--thm-primary);
  position: absolute;
  bottom: -33px;
  left: 0;
  transition: transform 500ms ease;
  transform: scale(0, 1);
  transform-origin: left center;
  z-index: -1;
}

.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a,
.stricky-header .main-menu__list > li.current > a,
.stricky-header .main-menu__list > li:hover > a {
  color: var(--thm-black);
}

.main-menu .main-menu__list > li.current > a::before,
.main-menu .main-menu__list > li:hover > a::before,
.stricky-header .main-menu__list > li.current > a::before,
.stricky-header .main-menu__list > li:hover > a::before {
  transform: scale(1, 1);
  transform-origin: right center;
}

.main-menu .main-menu__list > li.current > a::before {
  background-color: var(--thm-primary);
}

.main-menu .main-menu__list > li:hover > a::before {
  background-color: var(--thm-primary);
}

.main-menu .main-menu__list li ul,
.stricky-header .main-menu__list li ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 220px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: 500ms ease;
  z-index: 99;
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: var(--thm-border-radius);
  border-bottom-right-radius: var(--thm-border-radius);
}

.main-menu .main-menu__list li:hover > ul,
.stricky-header .main-menu__list li:hover > ul {
  opacity: 1;
  visibility: visible;
}

.main-menu .main-menu__list li ul li,
.stricky-header .main-menu__list li ul li {
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}

.main-menu .main-menu__list li ul li + li,
.stricky-header .main-menu__list li ul li + li {
  border-top: 1px solid RGBA(var(--thm-black), 0.1);
}

.main-menu .main-menu__list li ul li a,
.stricky-header .main-menu__list li ul li a {
  font-size: 16px;
  line-height: 30px;
  color: var(--thm-black);
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 500ms;
}

.main-menu .main-menu__list li ul > li:last-child > a,
.stricky-header .main-menu__list li ul > li:last-child > a {
  border-bottom-left-radius: var(--thm-border-radius);
  border-bottom-right-radius: var(--thm-border-radius);
}

.main-menu .main-menu__list li ul li:hover > a,
.stricky-header .main-menu__list li ul li:hover > a {
  background-color: var(--thm-primary);
  color: #fff;
}

.main-menu .main-menu__list li ul li > ul,
.stricky-header .main-menu__list li ul li > ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li > ul.right-align,
.stricky-header .main-menu__list li ul li > ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}

.main-menu .main-menu__list li ul li > ul ul,
.stricky-header .main-menu__list li ul li > ul ul {
  display: none;
}

.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  transform: translateY(-120%);
  transition: transform 500ms ease, visibility 500ms ease;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  transform: translateY(0);
  visibility: visible;
}

.stricky-header .main-menu__inner {
  box-shadow: none;
  padding-right: 0;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.mobile-nav__buttons {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .mobile-nav__buttons {
    display: none;
  }
}

.mobile-nav__buttons a {
  font-size: 20px;
  color: var(--thm-black);
  cursor: pointer;
}

.mobile-nav__buttons a + a {
  margin-left: 10px;
}

.mobile-nav__buttons a:hover {
  color: var(--thm-base);
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: var(--thm-primary);
  cursor: pointer;
  transition: 500ms;
  margin-right: 20px;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--thm-black);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
# Navigations Two
--------------------------------------------------------------*/
.main-header-two {
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 999;
  transition: all 500ms ease;
}

.main-header-two:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  background-color: rgba(var(--thm-base-rgb), 0.15);
  z-index: -1;
}

.main-menu-two .main-menu__list > li > a,
.stricky-header .main-menu-two .main-menu__list > li > a {
  color: var(--thm-base);
}

.main-menu-two .main-menu__list > li.current > a,
.main-menu-two .main-menu__list > li:hover > a,
.stricky-header .main-menu-two .main-menu__list > li.current > a,
.stricky-header .main-menu-two .main-menu__list > li:hover > a {
  color: var(--thm-base);
}

.main-menu-two .main-menu-wrapper__right {
  padding: 40px 0;
}

.main-menu-two .main-menu__search,
.main-menu-two .main-menu__user {
  background-color: transparent;
  color: var(--thm-base);
  height: inherit;
  width: inherit;
}

.main-menu-two .main-menu__search:hover {
  color: var(--thm-primary);
}

.main-menu-two .main-menu__user {
  margin-left: 20px;
}

.main-menu-two .main-menu__user:hover {
  color: var(--thm-primary);
}

.stricky-header.main-menu-two {
  padding: 0 0;
  background-color: var(--thm-black);
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }

  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@keyframes shapeMover {
  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px)
      translateX(0);
  }

  50% {
    transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px)
      translateX(20px);
  }
}

@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }

  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

@keyframes squareMover {
  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }

  20%,
  60% {
    -webkit-transform: translate(20px, 40px) rotate(180deg);
    transform: translate(20px, 40px) rotate(180deg);
  }

  30%,
  80% {
    -webkit-transform: translate(40px, 60px) rotate(0deg);
    transform: translate(40px, 60px) rotate(0deg);
  }
}

@keyframes treeMove {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}

/*--------------------------------------------------------------
#     Zoom Fade Css
--------------------------------------------------------------*/
.zoom-fade {
  animation-name: zoom-fade;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoom-fade;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-moz-keyframes service_hexagon_2 {
  0% {
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -moz-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-o-keyframes service_hexagon_2 {
  0% {
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes service_hexagon_2 {
  0% {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

/*--------------------------------------------------------------
    Float Bob Y Animation Css
--------------------------------------------------------------*/
@-webkit-keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transform: translateX(-50%);
  transform-origin: right center;
  transition: transform 500ms ease-in, opacity 500ms linear,
    visibility 500ms ease-in;
  z-index: 999;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--thm-black);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.mobile-nav__content .thm-btn {
  padding: 8px 0;
  width: 100%;
  text-align: center;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--thm-text-dark);
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list li a {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-family: var(--thm-font);
  font-weight: 500;
  height: 46px;
  align-items: center;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--thm-secondary);
}

.mobile-nav__content .main-menu__list li a button {
  width: 30px;
  height: 30px;
  background-color: var(--thm-primary);
  border: none;
  outline: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-90deg);
  transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--thm-black);
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
  color: var(--thm-text-dark);
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: flex;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--thm-primary);
  transition: 500ms;
}

.mobile-nav__social a + a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: #ffffff;
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  color: var(--thm-text-dark);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}

.mobile-nav__contact li + li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--thm-primary);
}

.mobile-nav__contact li > i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--thm-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-110%);
  transition: transform 500ms ease, opacity 500ms ease;
}

.search-popup.active {
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--thm-black);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
  position: relative;
}

.search-popup__content form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 0px;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--thm-primary);
}

.search-popup__content .thm-btn:hover {
  color: #fff;
}

.search-popup__content .thm-btn i {
  margin: 0;
}

/*--------------------------------------------------------------
# Main Slider
--------------------------------------------------------------*/
.main-slider {
  overflow: hidden;
  position: relative;
}

.main-slider .swiper-slide {
  position: relative;
  background-color: #faf5ee;
}

.main-slider .image-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  /* transform-origin: top center; */
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 4000ms ease;
  transition: -webkit-transform 4000ms ease;
  transition: transform 4000ms ease;
  transition: transform 4000ms ease, -webkit-transform 7000ms ease;
  z-index: -1;
}
.main-slider .image-layers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transform-origin: top center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 7000ms ease;
  transition: -webkit-transform 7000ms ease;
  transition: transform 7000ms ease;
  transition: transform 7000ms ease, -webkit-transform 7000ms ease;
  z-index: -1;
}

.main-slider .image-layer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(var(--thm-black-rgb), 0.6);
}

.main-slider .swiper-slide-inner {
  position: relative;
  display: block;
  text-align: center;
  padding-top: 280px;
  padding-bottom: 255px;
}

.main-slider h2 {
  position: relative;
  display: block;
  margin: 0;
  color: var(--thm-primary);
  font-size: 80px;
  line-height: 90px;
  font-weight: 400;
  font-family: var(--thm-reey-font);
  margin-bottom: 25px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-90px);
  transform: translateY(-90px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
  z-index: 2;
}

.main-slider h2:before {
  position: absolute;
  bottom: -7px;
  right: 370px;
  /* background-image: url(/_next/static/media/main-slider-shape-1.d5aadaec.png); */
  background-repeat: no-repeat;
  width: 400px;
  height: 24px;
  content: "";
}

.main-slider p {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 34px;
  color: var(--thm-base);
  font-weight: 500;
  opacity: 0;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 0;
  transition-delay: 0;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
  z-index: 3;
}

.main-slider .swiper-slide-active .image-layer {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.main-slider .swiper-slide-active .image-layers {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.main-slider .swiper-slide-active h2,
.main-slider .swiper-slide-active p {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.main-slider-button-next {
  position: relative;
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  color: #002B41;
  cursor: pointer;
  z-index: 9;
  margin-right: 100px;
  font-size: 25px;
  border: 2px solid #002B41;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
  transition: 0.5s;
}

.main-slider-button-prev {
  position: relative;
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  color: #002B41;
  cursor: pointer;
  z-index: 9;
  margin-left: 100px;
  font-size: 25px;
  border: 2px solid #002B41;
  transform: rotate(-180deg);
  opacity: 0.2;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-slider-button-prev,
.main-slider-button-next:focus {
  outline: none;
}

.main-slider-button-prev:hover,
.main-slider-button-next:hover {
  opacity: 1;
}

/*--------------------------------------------------------------
# Banner Two
--------------------------------------------------------------*/
.banner-two {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: var(--thm-black);
  padding-top: 334px;
  padding-bottom: 126px;
}

.banner-two .banner-bg-slide {
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.banner-bg-slide-overly {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--thm-black-rgb), 0.6);
}

.banner-two__content {
  position: relative;
  display: block;
}

.banner-two__sub-title {
  font-size: 60px;
  color: var(--thm-primary);
  font-family: var(--thm-reey-font);
  line-height: 60px;
  margin: 0;
  padding-left: 231px;
}

.banner-two__title {
  font-size: 70px;
  color: var(--thm-base);
  font-weight: 700;
  line-height: 60px;
  letter-spacing: var(--thm-letter-spacing);
  margin-top: -6px;
  text-align: center;
  margin-bottom: 70px;
}

.banner-two__bottom-text {
  font-size: 18px;
  color: var(--thm-base);
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 52px;
}

.banner-two__content .tour-search-one__btn {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

/*--------------------------------------------------------------
# Tour Search
--------------------------------------------------------------*/
.tour-search {
  position: relative;
  display: block;
  margin-top: -50px;
  z-index: 2;
}

.tour-search-box {
  position: relative;
  display: block;
}

.tour-search-one {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 30px 0px rgb(0, 0, 0, 0.05);
  border-radius: var(--thm-border-radius);
}

.tour-search-one__inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.tour-search-one__inputs {
  -webkit-box-flex: 1;
  flex: 1 1 calc(100% - 224px);
  max-width: calc(100% - 224px);
  flex-wrap: wrap;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.tour-search-one__btn-wrap {
  -webkit-box-flex: 1;
  flex: 1 1 224px;
  max-width: 224px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  z-index: 0;
}

.tour-search-one__btn {
  padding: 33px 60px 33px;
  font-size: 16px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.tour-search-one__btn:after {
  right: -1px;
}

.tour-search-one__input-box {
  -webkit-box-flex: 1;
  flex: 1 1 33.333%;
  max-width: 33.333%;
  padding: 0px 40px;
  padding-top: 27px;
  padding-bottom: 25px;
  max-height: 100px;
}

.tour-search-one__input-box:nth-child(2) {
  border-left: 1px solid #ebe6de;
}

.tour-search-one__input-box:nth-child(3) {
  border-left: 1px solid #ebe6de;
}

.tour-search-one__input-box-last {
  padding-top: 24px;
  padding-bottom: 24px;
}

.tour-search-one__input-box label {
  display: block;
  margin: 0;
  color: var(--thm-gray);
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0;
  box-shadow: none;
  line-height: 18px;
}

.tour-search-one__input-box
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]),
.tour-search-one__input-box textarea {
  border: none;
  outline: none;
  font-size: 18px;
  color: var(--thm-black);
  display: block;
  line-height: 28px;
}

.tour-search-one__input-box ::-webkit-input-placeholder {
  color: var(--thm-black);
  opacity: 1;
}

.tour-search-one__input-box ::-moz-placeholder {
  color: var(--thm-black);
  opacity: 1;
}

.tour-search-one__input-box :-ms-input-placeholder {
  color: var(--thm-black);
  opacity: 1;
}

.tour-search-one__input-box ::-ms-input-placeholder {
  color: var(--thm-black);
  opacity: 1;
}

.tour-search-one__input-box ::placeholder {
  color: var(--thm-black);
  opacity: 1;
}

.tour-search-one__input-box :-ms-input-placeholder {
  color: var(--thm-black);
}

.tour-search-one__input-box ::-ms-input-placeholder {
  color: var(--thm-black);
}

.tour-search-one__input-box
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.tour-search-one__input-box .bootstrap-select > .dropdown-toggle {
  background-color: transparent;
  border: none;
  font-weight: 400;
  border-radius: 0;
  padding: 0;
  font-size: 18px;
  color: var(--thm-black);
  display: block;
  line-height: 28px;
}

.tour-search-one__input-box .bootstrap-select > .dropdown-toggle::after {
  display: none;
}

/*--------------------------------------------------------------
# Destinations One
--------------------------------------------------------------*/
.destinations-one {
  position: relative;
  display: block;
  padding: 120px 0 0;
}

.destinations-one .row {
  --bs-gutter-x: 10px;
}

.destinations-one__single {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.destinations-one__img {
  position: relative;
  display: block;
  border-radius: var(--thm-border-radius);
  overflow: hidden;
  z-index: 1;
}

.destinations-one__img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: var(--thm-border-radius);
  background-color: rgba(var(--thm-black-rgb), 0.5);
  transform: scale(1, 0);
  transition: transform 500ms ease;
  transform-origin: top center;
  z-index: 1;
}

.destinations-one__single:hover .destinations-one__img:before {
  transform: scale(1, 1);
  transform-origin: bottom center;
}

.destinations-one__img img {
  width: 100%;
  height: 285px;
  object-fit: cover;
  border-radius: var(--thm-border-radius);
  transform: scale(1);
  transition: transform 500ms ease;
}

.destinations-one__single:hover .destinations-one__img img {
  transform: scale(1.05);
}

.destinations-one__content {
  position: absolute;
  bottom: 43px;
  left: 50px;
  z-index: 2;
}

.destinations-one__sub-title {
  font-size: 20px;
  color: var(--thm-primary);
  font-family: var(--thm-reey-font);
  margin: 0;
  line-height: 30px;
  letter-spacing: var(--thm-letter-spacing);
  position: relative;
  z-index: 1;
}

.destinations-one__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--thm-letter-spacing);
  position: relative;
  display: block;
  z-index: 1;
}

.destinations-one__title > a {
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.destinations-one__title > a:hover {
  color: var(--thm-primary);
}

.destinations-one__button {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 30px;
  transition: all 500ms ease;
  transform: scaleX(0);
  z-index: 2;
}

.destinations-one__single:hover .destinations-one__button {
  transform: scaleX(1);
}

.destinations-one__button > a {
  font-size: 11px;
  font-weight: 700;
  color: var(--thm-base);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-color: var(--thm-primary);
  display: inline-block;
  border-radius: var(--thm-border-radius);
  padding: 0 15px 0;
  transition: all 500ms ease;
}

.destinations-one__button > a:hover {
  background-color: var(--thm-base);
  color: var(--thm-primary);
}

/*--------------------------------------------------------------
# About One
--------------------------------------------------------------*/
.about-one {
  position: relative;
  display: block;
  padding: 105px 0 111px;
}

.about-one-shape-1 {
  position: absolute;
  top: 180px;
  left: 300px;
}

.about-one-shape-1 img {
  -webkit-animation: animation1 5s ease-in infinite;
  animation: animation1 5s ease-in infinite;
}

.about-one-shape-2 {
  position: absolute;
  top: 70px;
  right: 100px;
}

.about-one__left {
  position: relative;
  display: block;
  margin-left: -45px;
  margin-right: -10px;
}

.about-one__img-box {
  position: relative;
  display: block;
}

.about-one__img {
  position: relative;
  display: block;
}

.about-one__img img {
  width: 100%;
}

.about-one__call {
  position: absolute;
  bottom: 216px;
  left: -95px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 25px 40px 24px;
  border-radius: var(--thm-border-radius);
}

.about-one__call:before {
  position: absolute;
  bottom: -20px;
  right: 26px;
  content: "";
  /* background-image: url(../assets/images/shapes/about-one-call-shape-1.png); */
  background-repeat: no-repeat;
  height: 20px;
  width: 33px;
}

.about-one__call-icon {
  display: flex;
  align-items: center;
}

.about-one__call-icon span {
  font-size: 25px;
  color: var(--thm-primary);
}

.about-one__call-number {
  margin-left: 20px;
}

.about-one__call-number p {
  font-size: 10px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 13px;
}

.about-one__call-number h4 {
  font-size: 18px;
  line-height: 20px;
}

.about-one__call-number h4 a {
  font-size: 18px;
  font-weight: 700;
  color: var(--thm-black);
}

.about-one__discount {
  position: absolute;
  top: 130px;
  right: -6px;
}

.about-one__discount h2 {
  font-size: 80px;
  color: var(--thm-primary);
  line-height: 80px;
  font-family: var(--thm-reey-font);
}

.about-one__discount h3 {
  font-size: 50px;
  font-weight: 700;
  margin-top: -27px;
}

.about-one__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 10px;
}

.about-one__right .section-title {
  margin-bottom: 37px;
}

.about-one__right-text {
  margin: 0;
  font-size: 16px;
}

.about-one__points {
  position: relative;
  display: block;
  margin-top: 34px;
  margin-bottom: 61px;
}

.about-one__points li {
  display: flex;
  align-items: center;
}

.about-one__points li + li {
  margin-top: 10px;
}

.about-one__points li .icon {
  height: 16px;
  min-width: 16px;
  background-color: var(--thm-primary);
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
  border-radius: 50%;
}

.about-one__points li .text {
  margin-left: 15px;
}

.about-one__points li .text p {
  color: var(--thm-black);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

/*--------------------------------------------------------------
# Popular Tours
--------------------------------------------------------------*/
.popular-tours {
  position: relative;
  display: block;
  padding: 0 0 185px;
}

.popular-tours__container {
  position: relative;
  display: block;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.popular-tours__single {
  position: relative;
  display: block;
}

.popular-tours__singles{
  position: relative;
  display: block;
  border: 1px solid #ebe6de;
  border-radius: 8px;
  height: 100%;
}

.popular-tours__buttons{
    padding: 0px 20px 20px;
}

.popular-tours__img {
  position: relative;
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}

.popular-tours__img img {
  width: 100%;
  height: 259px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
}

.popular-tours__single:hover .popular-tours__img img {
  opacity: 1;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.popular-tours__icon a {
  width: 38px;
  height: 38px;
  background-color: rgba(var(--thm-black-rgb), 0.2);
  border-radius: 50%;
  text-align: center;
  display: flex;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  align-items: center;
  justify-content: center;
  line-height: 38px;
}

.popular-tours__single:hover .popular-tours__icon a {
  background-color: var(--thm-base);
  color: var(--thm-primary);
}

.popular-tours__content {
  position: relative;
  display: block;
  border: 1px solid #ebe6de;
  border-top: 0;
  padding: 20px 40px 40px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 230px;
}

.popular-tours__contents {
  position: relative;
  display: block;
  border-top: 0;
  padding: 20px;
}
@media (max-width: 767px) {
  .popular-tours__content{
    height: 260px;
  }
}

.popular-tours__stars {
  font-size: 13px;
  font-weight: 500;
  color: var(--thm-gray);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  line-height: 23px;
  letter-spacing: -0.02em;
}

.popular-tours__stars i {
  font-size: 14px;
  color: #ffa801;
  margin-right: 6px;
}

.popular-tours__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin-top: 4px;
  margin-bottom: 7px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.popular-tours__title a {
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.popular-tours__title a:hover {
  color: var(--thm-primary);
}

.popular-tours__rate {
  font-size: 15px;
  margin: 0;
  letter-spacing: -0.02em;
}

.popular-tours__rate span {
  font-size: 20px;
  font-weight: 700;
  color: var(--thm-primary);
}

.popular-tours__meta {
  display: flex;
  align-items: center;
  background-color: #faf5ee;
  border-radius: var(--thm-border-radius);
  padding: 0 28px 0;
  margin-top: 19px;
}

.popular-tours__meta li + li {
  margin-left: 35px;
}

.popular-tours__meta a {
  font-size: 12px;
  color: var(--thm-gray);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.popular-tours__meta a:hover {
  color: var(--thm-primary);
}

.popular-tours__carousel .tns-nav {
  position: absolute;
  bottom: -65px;
  right: 0px;
  left: 0;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popular-tours__carousel .tns-nav button {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  background: var(--thm-black);
  margin: 0px 3px;
  border-radius: 50%;
  transition: all 100ms linear;
  transition-delay: 0.1s;
  zoom: 1;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.popular-tours__carousel .tns-nav button.tns-nav-active {
  background: var(--thm-primary);
  height: 10px;
  width: 10px;
}

.popular-tours__carousel .tns-nav button:focus {
  outline: none;
}

/*--------------------------------------------------------------
# Video One
--------------------------------------------------------------*/
.video-one {
  position: relative;
  display: block;
  padding: 135px 0 108px;
}

.video-one-bg {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.video-one-bg:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: rgba(41, 40, 55, 0.8);
  z-index: -1;
}

.video-one-bg.jarallax .jarallax-img {
  filter: grayscale(100%);
}

.video-one__left {
  position: relative;
  display: block;
}

.video-one__video-link {
  position: relative;
  display: block;
  margin-left: 15px;
}

.video-one__video-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 20px;
  color: var(--thm-base);
  background-color: var(--thm-primary);
  border-radius: var(--thm-border-radius);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.video-one__video-icon:before {
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  content: "";
  border: 1px solid #73737d;
  border-radius: var(--thm-border-radius);
}

.video-one__video-link .ripple,
.video-one__video-icon .ripple:before,
.video-one__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--thm-primary-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--thm-primary-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--thm-primary-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  border-radius: var(--thm-border-radius);
}

.video-one__video-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.video-one__video-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.video-one__tagline {
  font-size: 30px;
  color: var(--thm-primary);
  font-family: var(--thm-reey-font);
  margin: 0;
  padding-top: 67px;
  padding-bottom: 33px;
}

.video-one__title {
  font-size: 50px;
  color: var(--thm-base);
  font-weight: 700;
  line-height: 60px;
  letter-spacing: var(--thm-letter-spacing);
}

.video-one__right {
  position: relative;
  display: block;
  margin-left: 164px;
  margin-top: -15px;
}

.video-one__four-icon-boxes {
  position: relative;
  display: block;
  overflow: hidden;
}

.video-one__four-icon-boxes li {
  position: relative;
  max-width: 200px;
  width: 100%;
  text-align: center;
  border: 1px solid #75757f;
  padding-top: 38px;
  padding-bottom: 35px;
  border-radius: var(--thm-border-radius);
  margin-left: 6px;
  margin-bottom: 6px;
  float: left;
}

.video-one__four-icon-boxes li:nth-child(1) {
  margin-left: 0;
}

.video-one__four-icon-boxes li:nth-child(3) {
  margin-left: 0;
  margin-bottom: 0;
}

.video-one__four-icon-boxes li:nth-child(4) {
  margin-bottom: 0;
}

.video-one__icon-box {
  margin-bottom: 15px;
}

.video-one__icon-box span {
  font-size: 63px;
  color: var(--thm-primary);
  display: inline-block;
  transition: all 500ms ease;
}

.video-one__four-icon-boxes li:hover .video-one__icon-box span {
  transform: rotateY(180deg);
}

.video-one__icon-box-title {
  font-size: 17px;
  color: var(--thm-base);
  font-weight: 700;
}

.video-one__icon-box-title a {
  color: var(--thm-base);
  transition: all 500ms ease;
}

.video-one__four-icon-boxes li:hover .video-one__icon-box-title a {
  color: var(--thm-primary);
}

/*--------------------------------------------------------------
# Brand One
--------------------------------------------------------------*/
.brand-one {
  position: relative;
  display: block;
  background-color: var(--thm-primary);
  padding: 96px 0;
  z-index: 1;
}

.brand-one-shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

.brand-one__title {
  position: relative;
  display: block;
  padding: 25px 0 13px;
}

.brand-one__title h2 {
  font-size: 30px;
  color: var(--thm-base);
  font-weight: 400;
  line-height: 40px;
  font-family: var(--thm-reey-font);
}

.brand-one__main-content .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.brand-one__main-content .swiper-slide img {
  -webkit-transition: 500ms;
  background: white;
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
  transition: 500ms;
  max-width: 100%;
  height: 120px;
}

.brand-one__main-content .swiper-slide img:hover {
  opacity: 1;
}

/*--------------------------------------------------------------
# Testimonial One
--------------------------------------------------------------*/
.testimonial-one {
  position: relative;
  display: block;
  padding: 120px 0 0px;
}

.testimonial-one-shape-2 {
  position: absolute;
  bottom: 255px;
  left: 70px;
}

.testimonial-one-shape-3 {
  position: absolute;
  top: 155px;
  right: 260px;
}

.testimonial-one-shape-3 img {
  -webkit-animation: animation1 5s ease-in infinite;
  animation: animation1 5s ease-in infinite;
}

.testimonial-one__single {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 60px;
}

.testimonial-one__img {
  position: relative;
  display: block;
  height: 230px;
  width: 230px;
  margin: 0 auto 40px;
  border-radius: 50%;
  overflow: hidden;
}

.testimonial-one__img:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-radius: 50%;
  background-color: rgba(var(--thm-black-rgb), 0.5);
  transform: scale(0);
  transition: all 500ms ease;
  z-index: 1;
}

.testimonial-one__single:hover .testimonial-one__img:before {
  transform: scale(1);
}

.testimonial-one__img img {
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.testimonial-one__single:hover .testimonial-one__img img {
  transform: scale(1.2);
}

.testimonail-one__content {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.07);
  padding: 50px 40px 43px;
  text-align: center;
  border-radius: var(--thm-border-radius);
}

.testimonail-one__content:before {
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  height: 19px;
  width: 63px;
  background-repeat: no-repeat;
  /* background-image: url(../assets/images/shapes/testimonial-one-shape-1.png); */
  content: "";
}

.testimonial-one__top-revivew-box {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.testimonial-one__top-revivew-box i {
  color: #ffa801;
  font-size: 16px;
}

.testimonial-one__top-revivew-box i + i {
  margin-left: 3px;
}

.testimonial-one__text {
  margin: 0;
  line-height: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 14px;
}

.testimonial-one__client-name {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.testimonial-one__client-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--thm-primary);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0;
  line-height: 24px;
}

.testimonial-one__carousel {
  position: relative;
  z-index: 1;
}

.testimonial-one__carousel .tns-nav {
  position: absolute;
  bottom: 6px;
  right: 0px;
  left: 0;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-one__carousel .tns-nav button {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  background: var(--thm-black);
  opacity: 0.2;
  margin: 0px 3px;
  border-radius: 50%;
  transition: all 100ms linear;
  transition-delay: 0.1s;
  zoom: 1;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.testimonial-one__carousel button.tns-nav-active {
  background: var(--thm-primary);
  opacity: 1;
  height: 10px;
  width: 10px;
}

.testimonial-one__carousel .tns-nav button:focus {
  outline: none;
}

.testimonial-one__carousel .tns-ovh {
  overflow: visible;
}

.testimonial-one__carousel .tns-item {
  opacity: 0;
  transition: all 100ms linear;
  transition-delay: 0.1s;
}

.testimonial-one__carousel .tns-slide-active {
  opacity: 1;
  transition: all 300ms linear;
  transition-delay: 0.1s;
}

/*--------------------------------------------------------------
# Gallery One
--------------------------------------------------------------*/
.gallery-one {
  position: relative;
  display: block;
  padding: 380px 0 90px;
  background-color: #faf5ee;
  margin-top: -270px;
}

.gallery-one-bg {
  position: absolute;
  top: 128px;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: center;
  height: 560px;
  width: 1157px;
  opacity: 0.6;
}

.gallery-one__container-box {
  position: relative;
  display: block;
  max-width: 1620px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.gallery-one__content {
  position: relative;
  display: block;
  margin-right: -5px;
  margin-left: -5px;
}

.gallery-one__content li {
  position: relative;
  display: block;
  float: left;
  width: 20%;
  padding: 0 5px 0;
  overflow: hidden;
  margin-bottom: 30px;
}

.gallery-one__content li:last-child {
  margin-right: 0;
}

.gallery-one__img-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: var(--thm-border-radius);
}

/* .gallery-one__img-box:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(var(--thm-primary-rgb), 0.9);
  border-radius: var(--thm-border-radius);
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transform: translateY(-105%);
  -ms-transform: translateY(-105%);
  -o-transform: translateY(-105%);
  -moz-transform: translateY(-105%);
  transform: translateY(-105%);
  z-index: 1;
} */

.gallery-one__content li:hover .gallery-one__img-box:before {
  opacity: 1;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  -moz-transform: translateY(0%);
  transform: translateY(0%);
}

.gallery-one__img-box img {
  width: 100%;
  min-height: 270px;
  object-fit: cover;
  border-radius: var(--thm-border-radius);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
}

.gallery-one__content li:hover .gallery-one__img-box img {
  transform: scale(1.05);
}

.gallery-one__iocn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  z-index: 2;
}

.gallery-one__content li:hover .gallery-one__iocn {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: all 500ms ease 500ms;
  -webkit-transition: all 500ms ease 500ms;
  -ms-transition: all 500ms ease 500ms;
  -o-transition: all 500ms ease 500ms;
}

.gallery-one__iocn a {
  color: #ffffff;
  font-size: 35px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.gallery-one__iocn a:hover {
  color: var(--thm-black);
}

.mfp-figure figure img.mfp-img {
  border-radius: 10px !important;
  padding: 0;
}

.mfp-counter {
  top: 50px;
  font-size: 20px;
  line-height: 20px;
}

/*--------------------------------------------------------------
# Why Choose
--------------------------------------------------------------*/
.why-choose {
  position: relative;
  display: block;
}

.why-choose__container {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.why-choose__left {
  position: relative;
  display: block;
  float: left;
  width: 47%;
}

.why-choose__left-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 764px;
}

.why-choose__toggle {
  position: absolute;
  top: 100px;
  right: 100px;
  height: 171px;
  width: 171px;
  background-color: var(--thm-primary);
  box-shadow: 0px 20px 20px 0px rgb(21, 29, 42, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  animation: fa-spin 5s ease infinite;
  z-index: 1;
}

.why-choose__toggle:before {
  position: absolute;
  height: 11px;
  width: 25px;
  right: 32px;
  bottom: 0;
  content: "";
  /* background-image: url(../assets/images/shapes/why-choo-toggle-shape.png); */
  background-repeat: no-repeat;
  transform: rotate(-27deg);
  z-index: -1;
}

.why-choose__toggle p {
  font-size: 24px;
  color: var(--thm-base);
  margin: 0;
  line-height: 39px;
  text-align: center;
  font-family: var(--thm-reey-font);
  position: relative;
  top: 8px;
}

.why-choose__right {
  position: relative;
  display: block;
  float: right;
  width: 53%;
  padding: 123px 0 108px;
  background-color: var(--thm-black);
  padding-left: 120px;
}

.why-choose__right-map {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 500px;
  width: 434px;
  opacity: 0.1;
}

.why-choose__right:before {
  position: absolute;
  top: 120px;
  bottom: 120px;
  background-color: var(--thm-primary);
  width: 12px;
  left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  content: "";
}

.why-choose__right-content {
  position: relative;
  display: block;
  max-width: 520px;
  width: 100%;
}

.why-choose__right .section-title {
  margin-bottom: 37px;
}

.why-choose__right .section-title__title {
  color: var(--thm-base);
}

.why-choose__right-text {
  color: #a9a8b6;
  margin: 0;
}

.why-choose__list {
  position: relative;
  display: block;
  margin-top: 41px;
}

.why-choose__list li {
  position: relative;
  display: flex;
}

.why-choose__list li + li {
  margin-top: 40px;
}

.why-choose__list li .icon {
  position: relative;
  top: 8px;
  display: inline-block;
  transition: all 500ms ease;
}

.why-choose__list li:hover .icon {
  transform: rotateY(180deg);
}

.why-choose__list li .icon span {
  font-size: 63px;
  color: var(--thm-primary);
}

.why-choose__list li .text {
  margin-left: 40px;
}

.why-choose__list li .text h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 11px;
  color: var(--thm-base);
}

.why-choose__list li .text p {
  font-size: 16px;
  color: #a9a8b6;
  font-weight: 500;
  margin: 0;
}

/*--------------------------------------------------------------
# News One
--------------------------------------------------------------*/
.news-one {
  position: relative;
  display: block;
  padding: 80px 0 84px;
}

.news-one__top {
  position: relative;
  display: block;
}

.news-one__top-left {
  position: relative;
  display: block;
}

.news-one__top-left .section-title {
  margin-top: 4px;
}

.news-one__top-right {
  position: relative;
  display: block;
  float: right;
  margin-bottom: 15px;
}

.news-one__bottom {
  position: relative;
  display: block;
}

.news-one__bottom:before {
  position: absolute;
  top: -265px;
  left: 600px;
  right: -10000000px;
  bottom: 332px;
  content: "";
  background-color: #faf5ee;
  z-index: -1;
}

.news-one__single {
  position: relative;
  display: block;
  margin-bottom: 26px;
  border: 1px solid #ebe6de;
  border-radius: 8px;
  height: 100%;
}

.news-one__img {
  position: relative;
  display: block;
  border-radius: var(--thm-border-radius);
  overflow: hidden;
}

.news-one__img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: var(--thm-border-radius);
  transform: scale(1);
  transition: all 500ms ease;
}

.news-one__single:hover .news-one__img img {
  transform: scale(1.05);
}

.news-one__img > a {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--thm-black-rgb), 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--thm-base);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-30%);
  border-radius: var(--thm-border-radius);
}

.news-one__img > a > span {
  position: relative;
}

.news-one__img > a > span::before {
  content: "";
  width: 20px;
  height: 2px;
  background-color: var(--thm-base);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 500ms ease;
}

.news-one__img > a > span::after {
  content: "";
  transition: all 500ms ease;
  width: 2px;
  height: 20px;
  background-color: var(--thm-base);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.news-one__img > a:hover > span::before,
.news-one__img > a:hover > span::after {
  background-color: var(--thm-primary);
}

.news-one__single:hover .news-one__img > a {
  visibility: visible;
  transform: translateY(0%);
  opacity: 1;
}

.news-one__date {
  height: 65px;
  width: 120px;
  background-color: var(--thm-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.news-one__date p {
  font-size: 16px;
  margin: 0;
  line-height: 16px;
  text-align: center;
  color: var(--thm-base);
  font-weight: 700;
}

.news-one__date p span {
  font-size: 16px;
  text-transform: uppercase;
}

.news-one__content {
  position: relative;
  display: block;
  padding: 20px 15px;
}

.news-one__meta {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0;
}

.news-one__meta li + li {
  margin-left: 20px;
}

.news-one__meta li a {
  font-size: 14px;
  color: var(--thm-gray);
  transition: all 500ms ease;
}

.news-one__meta li a:hover {
  color: var(--thm-primary);
}

.news-one__meta li a i {
  color: var(--thm-primary);
  padding-right: 4px;
}

.news-one__title {
  font-size: 24px;
  line-height: 36px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  margin-bottom: 20px;
}

.news-one__title a {
  color: var(--thm-black);
  transition: all 500ms ease;
}

.news-one__title a:hover {
  color: var(--thm-primary);
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: var(--thm-black);
}

.site-footer__top {
  position: relative;
  display: block;
}

.site-footer__top-inner {
  position: relative;
  display: block;
  padding: 127px 0 60px;
  z-index: 1;
}

.footer-widget__about-logo img{
  width: 150px;
}

.footer-widget__about {
  position: relative;
  display: block;
  margin-right: 70px;
  margin-top: -8px;
}

.footer-widget__about-text {
  font-size: 15px;
  color: #a9a8b6;
  margin: 0;
  border-bottom: 1px solid rgba(var(--thm-base-rgb), 0.1);
  line-height: 32px;
  padding-top: 29px;
  padding-bottom: 24px;
  margin-bottom: 18px;
}

.footer-widget__about-contact li {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-widget__about-contact li + li {
  margin-top: 2px;
}

.footer-widget__about-contact li .icon {
  display: flex;
  align-items: center;
}

.footer-widget__about-contact li .icon i {
  font-size: 15px;
  color: var(--thm-primary);
}

.footer-widget__about-contact li .text {
  margin-left: 20px;
}

.footer-widget__about-contact li .text p {
  font-size: 15px;
  font-weight: 500;
  color: #a9a8b6;
  margin: 0;
}

.footer-widget__about-contact li .text a {
  font-size: 15px;
  font-weight: 500;
  color: #a9a8b6;
  transition: all 500ms ease;
}

.footer-widget__about-contact li .text a:hover {
  color: var(--thm-primary);
}

.footer-widget__title {
  font-size: 18px;
  color: var(--thm-base);
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 25px;
}

.footer-widget__company {
  position: relative;
  display: block;
}

.footer-widget__company-list {
  position: relative;
  display: block;
}

.footer-widget__company-list li + li {
  margin-top: 5px;
}

.footer-widget__company-list li a {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: #a9a8b6;
  transition: all 500ms ease;
}

.footer-widget__company-list li a:hover {
  padding-left: 20px;
  color: var(--thm-base);
}

.footer-widget__company-list li a:before {
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  width: 10px;
  height: 2px;
  content: "";
  background-color: var(--thm-primary);
  transform: translateY(-50%);
  opacity: 0;
  transition: all 500ms ease;
}

.footer-widget__company-list li a:hover:before {
  opacity: 1;
}

.footer-widget__explore {
  position: relative;
  display: block;
}

.footer-widget__explore-list {
  position: relative;
  display: block;
}

.footer-widget__explore-list {
  position: relative;
  display: block;
}

.footer-widget__explore-list li + li {
  margin-top: 5px;
}

.footer-widget__explore-list li a {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: #a9a8b6;
  transition: all 500ms ease;
}

.footer-widget__explore-list li a:hover {
  padding-left: 20px;
  color: var(--thm-base);
}

.footer-widget__explore-list li a:before {
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  width: 10px;
  height: 2px;
  content: "";
  background-color: var(--thm-primary);
  transform: translateY(-50%);
  opacity: 0;
  transition: all 500ms ease;
}

.footer-widget__explore-list li a:hover:before {
  opacity: 1;
}

.footer-widget__newsletter {
  position: relative;
  display: block;
}

.footer-widget__newsletter-form {
  position: relative;
  display: block;
  padding-top: 12px;
}

.footer-widget__newsletter-input-box {
  position: relative;
  display: block;
}

.footer-widget__newsletter-input-box input[type="email"] {
  font-size: 13px;
  color: #a9a8b6;
  font-weight: 500;
  text-transform: capitalize;
  height: 67px;
  width: 100%;
  background: #292837;
  border: none;
  border-radius: var(--thm-border-radius);
  outline: none;
  text-align: center;
  padding: 0 30px;
}

.footer-widget__newsletter-btn {
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  color: var(--thm-base);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-color: var(--thm-primary);
  outline: none;
  border: none;
  border-radius: var(--thm-border-radius);
  padding: 17px 50px 16px;
  margin-top: 10px;
  transition: all 500ms ease;
}

.footer-widget__newsletter-btn:hover {
  background-color: var(--thm-base);
  color: var(--thm-primary);
}

.footer-widget__newsletter-bottom {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.footer-widget__newsletter-bottom-icon {
  height: 18px;
  width: 18px;
  border: 1px solid #a9a8b6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-widget__newsletter-bottom-icon i {
  font-size: 8px;
  color: #a9a8b6;
}

.footer-widget__newsletter-bottom-text {
  margin-left: 10px;
}

.footer-widget__newsletter-bottom-text p {
  font-size: 15px;
  margin: 0;
  color: #a9a8b6;
  font-weight: 500;
}

.site-footer__bottom-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--thm-base);
  border-top-left-radius: 10px;
  padding: 31px 0;
  padding-left: 160px;
  z-index: 1;
}

.site-footer__bottom-inner:before {
  position: absolute;
  top: 0px;
  left: 2px;
  right: -100000000px;
  bottom: -5px;
  content: "";
  background-color: var(--thm-base);
  border-top-left-radius: 8px;
  z-index: -1;
}

.footer-widget__social {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.footer-widget__social a {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--thm-black);
  background-color: #faf5ee;
  border-radius: 50%;
  font-size: 15px;
  height: 50px;
  width: 50px;
  transform: rotate(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.footer-widget__social a:hover {
  color: var(--thm-base);
  transform: rotate(360deg);
}

.footer-widget__social a:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--thm-primary);
  border-radius: 50%;
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.footer-widget__social a:hover:before {
  transform: scaleX(1);
}

.footer-widget__social a + a {
  margin-left: 10px;
}

.site-footer__bottom-right p {
  font-size: 15px;
  color: var(--thm-gray);
  font-weight: 500;
  margin: 0;
}

.site-footer__bottom-right p a {
  color: var(--thm-gray);
  transition: all 500ms ease;
}

.site-footer__bottom-right p a:hover {
  color: var(--thm-primary);
}

.site-footer__bottom-left-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.site-footer__bottom-left-arrow a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-primary);
  border-top-left-radius: 8px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom-left-arrow a:hover {
  color: var(--thm-base);
  background-color: #292837;
}

.site-footer__bottom-left-arrow a span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  color: var(--thm-base);
  align-items: center;
}

/*--------------------------------------------------------------
# Three Icon Box
--------------------------------------------------------------*/
.three-icon-box {
  position: relative;
  display: block;
  background-color: #faf5ee;
  padding: 72px 0 70px;
}

.three-icon-box__single {
  position: relative;
  display: flex;
}

.three-icon-box__icon {
  position: relative;
  display: inline-block;
  top: 1px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.three-icon-box__single:hover .three-icon-box__icon {
  transform: scale(1.2);
}

.three-icon-box__icon span {
  font-size: 63px;
  color: var(--thm-primary);
}

.three-icon-box__content {
  margin-left: 30px;
}

.three-icon-box__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 13px;
}

.three-icon-box__text {
  font-size: 16px;
  margin: 0;
  line-height: 30px;
}

/*--------------------------------------------------------------
# Three Icon Box
--------------------------------------------------------------*/
.popular-tours-two {
  position: relative;
  display: block;
  padding: 80px 0 90px;
}

.popular-tours-two .popular-tours__single {
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Singapore Tour
--------------------------------------------------------------*/
.singapore-tour {
  position: relative;
  display: block;
}

.singapore-tour__container {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.singapore-tour__left {
  position: relative;
  display: block;
  float: left;
  width: 50%;
  min-height: 508px;
}

.singapore-tour-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.singapore-tour__right {
  position: relative;
  display: block;
  float: right;
  width: 50%;
  background-color: var(--thm-primary);
}

.singapore-tour-right-shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.05;
}

.singapore-tour__content {
  position: relative;
  display: block;
  padding-left: 180px;
  padding-top: 127px;
  padding-bottom: 100px;
}

.singapore-tour__title {
  font-size: 60px;
  color: var(--thm-base);
  font-family: var(--thm-reey-font);
  letter-spacing: var(--thm-letter-spacing);
  line-height: 60px;
  text-shadow: 10px 17.321px 0px rgb(0, 0, 0, 0.1);
  margin-bottom: 45px;
  padding-left: 37px;
}

.singapore-tour__text {
  font-size: 40px;
  font-weight: 700;
  color: var(--thm-base);
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  line-height: 48px;
  margin-bottom: 30px;
}

.singapore-tour__btn {
  background-color: var(--thm-base);
  color: var(--thm-black);
}

.singapore-tour__trusted {
  width: 171px;
  height: 171px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 30px 0px rgb(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: -71px;
  animation: fa-spin 5s ease infinite;
}

.singapore-tour__trusted p {
  font-size: 12px;
  color: var(--thm-primary);
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 0.1em;
}

.singapore-tour__trusted h2 {
  font-size: 36px;
  font-weight: 400;
  font-family: var(--thm-reey-font) !important;
  line-height: 44px !important;
}

.singapore-tour__trusted-icon {
  position: absolute;
  bottom: -5px;
  right: 37px;
  transform: rotate(146deg);
}

.singapore-tour__trusted-icon i {
  font-size: 40px;
  color: var(--thm-base);
}

.singapore-tour__trusted .odometer-formatting-mark {
  display: none;
}

/*--------------------------------------------------------------
# Video Two
--------------------------------------------------------------*/
.video-two {
  position: relative;
  display: block;
  padding: 135px 0 200px;
}

.video-two-bg {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.video-two-bg:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: rgba(41, 40, 55, 0.8);
  z-index: -1;
}

.video-two-bg.jarallax .jarallax-img {
  filter: grayscale(100%);
}

.video-two__inner {
  position: relative;
  display: block;
  text-align: center;
}

.video-two__inner .video-one__video-icon {
  margin: 0 auto;
}

/*--------------------------------------------------------------
# Counter One
--------------------------------------------------------------*/
.counter-one {
  position: relative;
  display: block;
  z-index: 1;
  margin-top: -100px;
}

.counter-one__container {
  position: relative;
  display: block;
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.counters-one__box {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 60px 0px rgb(0, 0, 0, 0.1);
  border-radius: var(--thm-border-radius);
  padding-top: 60px;
  padding-bottom: 25px;
  text-align: center;
}

.counter-one__single {
  position: relative;
  display: inline-block;
  max-width: 318px;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.counter-one__single:before {
  position: absolute;
  top: 0;
  bottom: 5px;
  left: 0;
  width: 1px;
  background-color: #ebe6de;
  content: "";
}

.counter-one__single:nth-child(1):before {
  display: none;
}

.counter-one__single h3 {
  font-size: 50px;
  color: var(--thm-primary);
  font-weight: 400;
  font-family: var(--thm-reey-font) !important;
  line-height: 65px !important;
  letter-spacing: var(--thm-letter-spacing);
}

.counter-one__text {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  line-height: 21px;
  margin-top: -10px;
}

/*--------------------------------------------------------------
# Destinations Two
--------------------------------------------------------------*/
.destinations-two {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.destinations-two-shape {
  position: absolute;
  top: 50px;
  left: 160px;
}

.destinations-two-shape img {
  -webkit-animation: animation1 5s ease-in infinite;
  animation: animation1 5s ease-in infinite;
}

.destinations-two__top {
  position: relative;
  display: block;
}

.destinations-two__top-left {
  position: relative;
  display: block;
}

.destinations-two__top-left .section-title {
  margin-top: 8px;
}

.destinations-two__top-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.destinations-two__top-img {
  position: relative;
  display: block;
  border-radius: var(--thm-border-radius);
  overflow: hidden;
  z-index: 1;
}

.destinations-two__top-img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(var(--thm-black-rgb), 0.3);
  border-radius: var(--thm-border-radius);
  transition: all 500ms ease;
  transform: scaleY(0);
  z-index: 1;
}

.destinations-two__top-single:hover .destinations-two__top-img:before {
  transform: scaleY(1);
}

.destinations-two__top-img img {
  width: 100%;
  border-radius: var(--thm-border-radius);
  transform: scale(1);
  transition: all 500ms ease;
}

.destinations-two__top-single:hover .destinations-two__top-img img {
  transform: scale(1.05);
}

.destinations-two__top-content {
  position: absolute;
  left: 50px;
  bottom: 40px;
  z-index: 2;
}

.destinations-two__top-sub-title {
  font-size: 20px;
  color: var(--thm-primary);
  margin: 0;
  line-height: 30px;
  letter-spacing: var(--thm-letter-spacing);
  font-family: var(--thm-reey-font);
  z-index: 1;
  position: relative;
}

.destinations-two__top-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: var(--thm-letter-spacing);
}

.destinations-two__top-title a {
  color: var(--thm-base);
  transition: all 500ms ease;
}

.destinations-two__top-single:hover .destinations-two__top-title a {
  color: var(--thm-primary);
}

.destinations-two__top-banner {
  position: relative;
  display: block;
  background-color: var(--thm-primary);
  border-radius: var(--thm-border-radius);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 39px;
  padding-bottom: 50px;
  text-align: center;
}

@media (min-width: 992px) {
  .destinations-two__top-banner {
    padding-left: 50px;
    padding-right: 0;
    text-align: left;
  }
}

.destinations-two__top-banner p {
  font-size: 11px;
  font-weight: 700;
  color: var(--thm-base);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0;
}

.destinations-two__top-banner h2 {
  font-size: 28px;
  color: var(--thm-base);
  font-family: var(--thm-reey-font);
  line-height: 35px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 11px;
}

.destinations-two__top-banner h2 span {
  position: relative;
  display: block;
  padding-left: 38px;
}

.destinations-two__btn {
  background-color: var(--thm-base);
  color: var(--thm-black);
}

/*--------------------------------------------------------------
# About Two
--------------------------------------------------------------*/
.about-two {
  position: relative;
  display: block;
}

.about-two-shape {
  position: absolute;
  top: -60px;
  right: 170px;
}

.about-two__left {
  position: relative;
  display: block;
  margin-left: -362px;
}

.about-tow__left-img {
  position: relative;
  display: block;
  overflow: hidden;
}

.about-tow__left-img:after {
  position: absolute;
  top: 0;
  left: -100%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  transform: skewX(-25deg);
  z-index: 1;
}

.about-tow__left-img:hover:after {
  -webkit-animation: shine 1.5s;
  animation: shine 1.5s;
}

.about-tow__left-img img {
  width: 100%;
  border-top-right-radius: 8px;
}

.about-two__video-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 140px;
  width: 150px;
  background-color: var(--thm-base);
  font-size: 25px;
  color: var(--thm-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  transition: all 500ms ease;
  z-index: 2;
}

.about-two__video-btn:hover {
  background-color: var(--thm-primary);
  color: var(--thm-base);
}

.about-two__right {
  position: relative;
  display: block;
  margin-left: 90px;
}

.about-two__right .section-title {
  margin-bottom: 27px;
}

.about-two__tour-points {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 33px;
}

.about-two__list {
  position: relative;
  display: block;
  float: left;
}

.about-two__list li {
  position: relative;
  display: flex;
  align-items: center;
}

.about-two__list li + li {
  margin-top: 2px;
}

.about-two__list li .icon {
  height: 16px;
  width: 16px;
  background-color: var(--thm-primary);
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
  border-radius: 50%;
}

.about-two__list li .text {
  margin-left: 8px;
}

.about-two__list li .text p {
  font-size: 16px;
  color: var(--thm-black);
  margin: 0;
  font-weight: 700;
}

.about-two__list-two {
  float: right;
}

.about-two__right-text-1 {
  font-size: 24px;
  color: var(--thm-primary);
  font-weight: 700;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.about-two__right-text-2 {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  padding-top: 25px;
  padding-bottom: 39px;
}

/*--------------------------------------------------------------
# Tour Types
--------------------------------------------------------------*/
.tour-types {
  position: relative;
  display: block;
  background-color: #faf5ee;
  overflow: hidden;
  padding: 124px 0 90px;
  z-index: 1;
}

.tour-types-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.6;
  z-index: -1;
}

.tour-types_list {
  position: relative;
  display: block;
  margin-right: -21px;
  margin-left: -21px;
  overflow: hidden;
}

.tour-types__single {
  position: relative;
  display: block;
  float: left;
  width: 20%;
  padding: 0 21px 0;
  overflow: hidden;
  margin-bottom: 30px;
}

.tour-types__content {
  position: relative;
  display: block;
  text-align: center;
  background-color: var(--thm-base);
  border-radius: var(--thm-border-radius);
  padding: 48px 0 42px;
  overflow: hidden;
  z-index: 1;
}

.tour-types__content:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--thm-primary);
  border-radius: var(--thm-border-radius);
  transform: translateY(100%);
  transition: all 500ms ease;
  z-index: -1;
}

.tour-types__single:hover .tour-types__content:before {
  transform: translateY(0);
}

.tour-types__icon {
  font-size: 64px;
  color: var(--thm-primary);
  margin-bottom: 19px;
  transition: all 500ms ease;
}

.tour-types__single:hover .tour-types__icon {
  transform: rotateY(180deg);
  color: var(--thm-base);
}

.tour-types__title {
  font-size: 17px;
  font-weight: 700;
  line-height: 27px;
  transition: all 500ms ease;
}

.tour-types__single:hover .tour-types__title {
  color: var(--thm-base);
}

/*--------------------------------------------------------------
# Brand One
--------------------------------------------------------------*/
.brand-two {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 118px 0 119px;
  border-bottom: 1px solid #ebe6de;
}

.brand-two .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.brand-two .swiper-slide img {
  -webkit-transition: 500ms;
  transition: 500ms;
  opacity: 0.3;
  max-width: 100%;
}

.brand-two .swiper-slide img:hover {
  opacity: 0.6;
}

/*--------------------------------------------------------------
# News Two
--------------------------------------------------------------*/
.news-two {
  position: relative;
  display: block;
  padding: 120px 0 84px;
}

.news-two_left {
  position: relative;
  display: block;
  margin-right: 70px;
}

.news-two_left .section-title {
  margin-top: 9px;
  margin-bottom: 37px;
}

.news-two__text {
  margin: 0;
}

.news-two__carousel .tns-ovh {
  margin-right: -360px;
}

@media (max-width: 575.9px) {
  .news-two__carousel .tns-ovh {
    margin-right: 0px;
  }
}

.news-two .tns-controls {
  position: relative;
  margin-top: 50px;
}

.news-two .tns-controls .tns-next {
  height: 65px;
  width: 65px;
  background: transparent;
  border-radius: 50%;
  color: #d8d8db;
  font-size: 23px;
  text-align: center;
  border: 2px solid #d8d8db;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 10px;
}

.news-two .tns-controls .tns-prev {
  height: 65px;
  width: 65px;
  background: transparent;
  border-radius: 50%;
  color: #d8d8db;
  font-size: 23px;
  text-align: center;
  transform: rotate(180deg);
  border: 2px solid #d8d8db;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-two .tns-controls .tns-next:hover,
.news-two .tns-controls .tns-prev:hover {
  color: var(--thm-black);
  border: 2px solid var(--thm-black);
}

.news-two .tns-controls span {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*--------------------------------------------------------------
# Book Now
--------------------------------------------------------------*/
.book-now {
  position: relative;
  display: block;
  background-color: var(--thm-primary);
  padding: 102px 0;
  z-index: 1;
}

.book-now-shape {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

.book-now__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book-now__left p {
  font-size: 30px;
  color: var(--thm-base);
  font-family: var(--thm-reey-font);
  margin: 0;
  line-height: 57px;
  padding-left: 10px;
}

.book-now__left h2 {
  font-size: 46px;
  font-weight: 700;
  color: var(--thm-base);
  line-height: 39px;
  letter-spacing: var(--thm-letter-spacing);
}

.book-now__btn {
  font-size: 15px;
  background-color: var(--thm-black);
  padding: 35px 15px 35px;
}

.book-now__btn:after {
  background-color: var(--thm-base);
}

.book-now__btn:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.book-now__btn:hover {
  color: var(--thm-black);
}

/*--------------------------------------------------------------
# page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  display: block;
}

.page-header__top {
  position: relative;
  display: block;
  padding: 104px 0;
}

.page-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
}

.page-header-bg-overly {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--thm-black-rgb), 0.6);
}

.page-header__top-inner {
  position: relative;
  display: block;
}

.page-header__top-inner h2 {
  font-size: 40px;
  color: var(--thm-base);
  font-weight: 700;
  line-height: 50px;
  letter-spacing: var(--thm-letter-spacing);
}

.page-header__bottom {
  position: relative;
  display: block;
  border-bottom: 1px solid #ebe6de;
  padding: 9px 0 11px;
}

.page-header__bottom-inner {
  position: relative;
  display: block;
}

.thm-breadcrumb {
  padding-top: 3px;
}

.thm-breadcrumb li {
  position: relative;
  display: inline-block;
  color: var(--thm-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 13px;
  font-weight: 500;
}

.thm-breadcrumb li + li {
  margin-left: 4px;
}

.thm-breadcrumb li a {
  color: var(--thm-gray);
  font-size: 13px;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li:hover a {
  color: var(--thm-primary);
}

.thm-breadcrumb li.active {
  color: var(--thm-primary);
}

/*--------------------------------------------------------------
# page Header
--------------------------------------------------------------*/
.news-details {
  position: relative;
  display: block;
  padding: 80px 0 80px;
}

.news-details__left {
  position: relative;
  display: block;
}

.news-details__img {
  position: relative;
  display: block;
  margin-bottom: 19px;
}

.news-details__img img {
  width: 100%;
  border-radius: var(--thm-border-radius);
  max-height: 500px;
  object-fit: cover;
}

.news-details__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 23px;
}

.news-details__text-1 {
  font-size: 16px;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.news-details__text-2 {
  font-size: 16px;
  margin: 0;
  padding-top: 34px;
  padding-bottom: 34px;
  letter-spacing: var(--thm-letter-spacing);
}

.news-details__text-3 {
  font-size: 16px;
  margin: 0;
  padding-bottom: 34px;
  letter-spacing: var(--thm-letter-spacing);
}

.news-details__text-4 {
  font-size: 16px;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.news-details__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid #ebe6de;
  padding: 35px 0 35px;
  margin-top: 48px;
}

.news-details__bottom p {
  margin: 0;
}

.news-details__tags span {
  color: var(--thm-black);
  font-weight: 700;
  font-size: 20px;
  letter-spacing: var(--thm-letter-spacing);
  margin-right: 15px;
}

.news-details__tags a {
  color: var(--thm-base);
  font-size: 11px;
  background: var(--thm-primary);
  display: inline-block;
  padding: 0px 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: var(--thm-border-radius);
  line-height: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-details__tags a:hover {
  background-color: var(--thm-black);
}

.news-details__tags a + a {
  margin-left: 5px;
}

.news-details__social-list {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.news-details__social-list a {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--thm-black);
  background-color: #faf5ee;
  border-radius: 50%;
  font-size: 15px;
  height: 50px;
  width: 50px;
  transform: rotate(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.news-details__social-list a:hover {
  color: var(--thm-base);
  transform: rotate(360deg);
}

.news-details__social-list a:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--thm-primary);
  border-radius: 50%;
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.news-details__social-list a:hover:before {
  transform: scaleX(1);
}

.news-details__social-list a + a {
  margin-left: 10px;
}

/* author */

.author-one {
  background-color: #faf5ee;
  padding: 60px 60px 52px;
  display: -webkit-box;
  display: flex;
  margin-bottom: 53px;
  border-radius: var(--thm-border-radius);
}

.author-one__image img {
  border-radius: var(--thm-border-radius);
}

.author-one__content {
  margin-left: 40px;
}

.author-one__content h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: var(--thm-letter-spacing);
}

.author-one__content p {
  margin: 0;
  margin-top: 26px;
  font-size: 16px;
  letter-spacing: var(--thm-letter-spacing);
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/

.comment-one__title,
.comment-form__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 51px;
  letter-spacing: var(--thm-letter-spacing);
}

.comment-one__single {
  display: -webkit-box;
  display: flex;
  border-bottom: 1px solid #ebe6de;
  padding-bottom: 60px;
  margin-bottom: 60px;
}

.comment-one__content {
  position: relative;
  margin-left: 45px;
}

.comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  color: var(--thm-black);
  font-weight: 700;
  margin-bottom: 30px;
  letter-spacing: var(--thm-letter-spacing);
}

.comment-one__content p {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  letter-spacing: var(--thm-letter-spacing);
}

.comment-one__btn {
  font-size: 11px;
  padding: 3px 30px;
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
}

.comment-one__btn:after {
  right: -2px;
}

.comment-one__image img {
  border-radius: 50%;
}

.comment-form .comment-form__title {
  margin-top: -13px;
}

.comment-form .row {
  --bs-gutter-x: 20px;
}

.comment-form__input-box input[type="text"],
.comment-form__input-box input[type="email"],
.comment-form__input-box input[type="number"]{
  height: 67px;
  width: 100%;
  border: none;
  background: #faf5ee;
  padding: 0 30px;
  margin-bottom: 20px;
  border-radius: var(--thm-border-radius);
  outline: none;
  font-size: 13px;
  font-weight: 500;
  color: var(--thm-gray);
}

.comment-form__input-box textarea {
  font-size: 13px;
  color: var(--thm-gray);
  font-weight: 500;
  height: 190px;
  width: 100%;
  background: #faf5ee;
  padding: 20px 40px 30px;
  border: none;
  border-radius: var(--thm-border-radius);
  outline: none;
  margin-bottom: 20px;
}

.comment-form__btn {
  font-size: 15px;
  padding: 17px 60px 17px;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar__single {
  border-radius: var(--thm-border-radius);
}

.sidebar__single + .sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 700;
  color: var(--thm-black);
  letter-spacing: var(--thm-letter-spacing);
}

.sidebar__search {
  background-color: var(--thm-base);
  padding: 44px 49px 49px;
  border: 1px solid #ebe6de;
}

.sidebar__search-form {
  position: relative;
}

.sidebar__search-form input[type="search"] {
  display: block;
  border: none;
  outline: none;
  background-color: #faf5ee;
  color: var(--thm-gray);
  font-size: 15px;
  font-weight: 500;
  padding-left: 30px;
  height: 78px;
  border-radius: var(--thm-border-radius);
  width: 100%;
  padding-right: 70px;
}

.sidebar__search-form ::-webkit-input-placeholder {
  color: var(--thm-gray);
  opacity: 1;
}

.sidebar__search-form ::-moz-placeholder {
  color: var(--thm-gray);
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: var(--thm-gray);
  opacity: 1;
}

.sidebar__search-form ::-ms-input-placeholder {
  color: var(--thm-gray);
  opacity: 1;
}

.sidebar__search-form ::placeholder {
  color: var(--thm-gray);
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: var(--thm-gray);
}

.sidebar__search-form ::-ms-input-placeholder {
  color: var(--thm-gray);
}

.sidebar__search-form button[type="submit"] {
  background-color: transparent;
  color: var(--thm-primary);
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  width: 70px;
  outline: none;
  border: none;
  padding: 0;
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 25px;
  background: var(--thm-base);
  border: 1px solid #ebe6de;
}

.sidebar__post .sidebar__title {
  margin-bottom: 15px;
}

.sidebar__post-list {
  margin: 0;
}

.sidebar__post-list li {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid #ebe6de;
  padding-bottom: 21px;
  margin-bottom: 20px;
}

.sidebar__post-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.sidebar__post-image {
  margin-right: 20px;
}

.sidebar__post-image > img {
  width: 60px;
  height: 65px;
  object-fit: cover;
  border-radius: var(--thm-border-radius);
}

.sidebar__post-content h3 {
  color: var(--thm-black);
  font-size: 18px;
  margin: 0;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: var(--thm-letter-spacing);
}

.sidebar__post-content .sidebar__post-content_meta {
  color: var(--thm-gray);
  font-size: 14px;
  display: block;
  font-weight: 400;
  letter-spacing: 0;
}

.sidebar__post-content .sidebar__post-content_meta i {
  margin-right: 2px;
  color: var(--thm-primary);
  font-size: 12px;
}

.sidebar__post-content h3 {
  text-transform: unset;
}

.sidebar__post-content h3 a {
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 16px;
}
.sidebar__post-content p{
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 14px;
}

.sidebar__post-content p:hover{
  color: var(--thm-primary);
}

.sidebar__post-content h3 a:hover {
  color: var(--thm-primary);
}

.sidebar__category {
  position: relative;
  display: block;
  background: var(--thm-base);
  border: 1px solid #ebe6de;
  padding: 44px 50px 38px;
}

.sidebar__category .sidebar__title {
  margin-bottom: 15px;
}

.sidebar__category-list {
  margin: 0;
}

.sidebar__category-list li + li {
  margin-top: 18px;
}

.sidebar__category-list li a {
  color: var(--thm-gray);
  font-size: 18px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: inline-block;
}

.sidebar__category-list li a:hover {
  color: var(--thm-primary);
}

.sidebar__tags {
  position: relative;
  display: block;
  background: var(--thm-base);
  border: 1px solid #ebe6de;
  padding: 45px 45px 50px;
}

.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 15px;
}

.sidebar__tags-list a {
  font-size: 11px;
  color: var(--thm-gray);
  font-weight: 700;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #faf5ee;
  display: inline-block;
  padding: 0px 15px;
  margin-left: 5px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 30px;
  border-radius: var(--thm-border-radius);
}

.sidebar__tags-list a + a {
  margin-left: 5px;
  margin-top: 10px;
}

.sidebar__tags-list a:hover {
  color: var(--thm-base);
  background: var(--thm-primary);
}

/*--------------------------------------------------------------
# Contact Page
--------------------------------------------------------------*/
.contact-page {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.contact-page__left {
  position: relative;
  display: block;
}

.contact-page__social {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.contact-page__social a {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--thm-black);
  background-color: #faf5ee;
  border-radius: 50%;
  font-size: 15px;
  height: 50px;
  width: 50px;
  transform: rotate(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.contact-page__social a:hover {
  color: var(--thm-base);
  transform: rotate(360deg);
}

.contact-page__social a:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--thm-primary);
  border-radius: 50%;
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.contact-page__social a:hover:before {
  transform: scaleX(1);
}

.contact-page__social a + a {
  margin-left: 10px;
}

/*--------------------------------------------------------------
# Information
--------------------------------------------------------------*/
.information {
  position: relative;
  display: block;
  padding: 0 0 90px;
}

.information__single {
  position: relative;
  height: 100%;
  border: 1px solid #ebe6de;
  border-radius: var(--thm-border-radius);
  padding: 49px 50px 49px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.information__icon {
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-size: 25px;
  color: var(--thm-black);
  background-color: #faf5ee;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.information__single:hover .information__icon {
  color: var(--thm-base);
}

.information__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 50%;
  background-color: var(--thm-primary);
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.information__single:hover .information__icon:before {
  transform: scaleX(1);
}

.information__text {
  margin-left: 20px;
}

.information__text p {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  line-height: 30px;
}

.information__text h4 {
  font-size: 18px;
  color: var(--thm-gray);
  line-height: 32px;
  letter-spacing: var(--thm-letter-spacing);
}

.information__text h4 a {
  color: var(--thm-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.information__single:hover .information__text h4 a {
  color: var(--thm-primary);
}

/*--------------------------------------------------------------
# Contact Page Google Map
--------------------------------------------------------------*/
.contact-page-google-map {
  position: relative;
  display: block;
}

.contact-page-google-map__one {
  position: relative;
  display: block;
  border: none;
  height: 560px;
  width: 100%;
}

/*--------------------------------------------------------------
# Tours List
--------------------------------------------------------------*/
.tours-list {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.tours-list__left {
  position: relative;
  display: block;
}

.tours-list__sidebar {
  position: relative;
  display: block;
}

.tours-list__sidebar-search {
  position: relative;
  display: block;
  background-color: #faf5ee;
  border-radius: var(--thm-border-radius);
  padding: 42px 50px 50px;
}

.tours-list__sidebar-search-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 22px;
}

.tours-list__sidebar-input input[type="text"] {
  height: 67px;
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--thm-base);
  border-radius: var(--thm-border-radius);
  padding: 0 30px;
  font-size: 13px;
  color: var(--thm-gray);
  font-weight: 500;
  margin-bottom: 10px;
}

.tours-list__sidebar-input
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.tours-list__sidebar-input .bootstrap-select > .dropdown-toggle {
  background-color: var(--thm-base);
  border: none;
  font-weight: 500;
  border-radius: var(--thm-border-radius);
  padding: 0 30px;
  font-size: 13px;
  color: var(--thm-gray);
  display: block;
  height: 67px;
  line-height: 67px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
  background-position: right 25.75px center;
}

.tours-list__sidebar-input .bootstrap-select > .dropdown-toggle::after {
  display: none;
}

.tours-list__sidebar-btn {
  font-size: 15px;
  padding: 16px 100px 17px;
  margin-top: 10px;
}

.tour-sidebar__sorter-wrap {
  padding: 38px 50px 49px;
  margin-top: 30px;
  border: 1px solid #ebe6de;
  border-radius: var(--thm-border-radius);
}

.tour-sidebar__sorter-single + .tour-sidebar__sorter-single {
  margin-top: 45px;
}

.tour-sidebar__sorter-top {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #ebe6de;
  padding-bottom: 10px;
  margin-bottom: 24px;
}

.tour-sidebar__sorter-top h3 {
  margin: 0;
  color: var(--thm-black);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-sidebar__sorter-toggler {
  border: none;
  outline: none !important;
  font-size: 18px;
  color: var(--thm-black);
  width: auto;
  background-color: transparent;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
  background-position: center right;
  width: 20px;
  height: 20px;
  padding: 0;
}

.tour-sidebar__sorter-toggler.toggled {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.tour-sidebar__price-range .form-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tour-sidebar__price-range .form-group p {
  font-size: 14px;
  font-weight: 700;
  color: var(--thm-gray);
  margin: 0;
  line-height: 24px;
}

.tour-sidebar__price-range .rangeslider-horizontal {
  height: 3px;
  background-color: var(--thm-primary);
}

.tour-sidebar__price-range .rangeslider__fill {
  background-color: var(--thm-primary);
}

.tour-sidebar__price-range .noUi-target {
  background: var(--thm-primary);
  border-radius: 0;
  border: 0px;
  box-shadow: none;
}

.tour-sidebar__price-range .rangeslider__handle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: var(--thm-primary);
  border: none;
  outline: none;
  box-shadow: none;
}

.tour-sidebar__price-range .rangeslider__handle::before,
.tour-sidebar__price-range .rangeslider__handle::after {
  display: none;
}

.tour-sidebar__price-range .noUi-connect {
  background-color: var(--thm-primary);
}

.tour-sidebar__sorter-inputs p {
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.tour-sidebar__sorter-inputs p + p {
  margin-top: 30px;
}

.tour-sidebar__sorter-inputs p label {
  margin: 0;
  font-weight: 400;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.tour-sidebar__sorter-inputs p label i {
  color: #faf5ee;
  font-size: 16px;
  line-height: 1em;
  position: relative;
  top: -2px;
}

.tour-sidebar__sorter-inputs p label i + i {
  margin-left: 0px;
}

.tour-sidebar__sorter-inputs p label i.active {
  color: #ffa801;
}

.tour-sidebar__sorter-inputs [type="checkbox"]:checked,
.tour-sidebar__sorter-inputs [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.tour-sidebar__sorter-inputs [type="checkbox"]:checked + label,
.tour-sidebar__sorter-inputs [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  color: var(--thm-gray);
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 1em;
}

.tour-sidebar__sorter-inputs [type="checkbox"]:checked + label:before,
.tour-sidebar__sorter-inputs [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 16px;
  height: 16px;
  border: 1px solid var(--thm-gray);
}

.tour-sidebar__sorter-inputs [type="checkbox"]:checked + label:after,
.tour-sidebar__sorter-inputs [type="checkbox"]:not(:checked) + label:after {
  content: "\f14a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 18px;
  color: var(--thm-primary);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.tour-sidebar__sorter-inputs [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.tour-sidebar__sorter-inputs [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.tours-list__inner {
  position: relative;
  display: block;
}

.tours-list__single {
  position: relative;
  display: flex;
  margin-bottom: 30px;
}

.tours-list__img {
  position: relative;
  display: block;
  max-width: 270px;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

.tours-list__img img {
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transform: scale(1);
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
}

.tours-list__single:hover .tours-list__img img {
  opacity: 1;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.tours-list__icon a {
  width: 38px;
  height: 38px;
  background-color: rgba(var(--thm-black-rgb), 0.2);
  border-radius: 50%;
  text-align: center;
  display: flex;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  align-items: center;
  justify-content: center;
  line-height: 38px;
}

.tours-list__single:hover .tours-list__icon a {
  background-color: var(--thm-base);
  color: var(--thm-primary);
}

.tours-list__content {
  position: relative;
  display: block;
  padding-left: 50px;
  padding-right: 49px;
  border: 1px solid #ece8e0;
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 41px;
}

.tours-list__stars {
  font-size: 13px;
  color: var(--thm-gray);
  font-weight: 500;
  letter-spacing: var(--thm-letter-spacing);
  line-height: 29px;
}

.tours-list__stars i {
  color: #ffa801;
}

.tours-list__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 7px;
}

.tours-list__title a {
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.tours-list__title a:hover {
  color: var(--thm-primary);
}

.tours-list__rate {
  font-size: 15px;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.tours-list__rate span {
  font-size: 20px;
  font-weight: 700;
  color: var(--thm-primary);
}

.tours-list__text {
  font-size: 16px;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  padding-top: 23px;
  padding-bottom: 33px;
}

.tours-list__meta {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #faf5ee;
  border-radius: var(--thm-border-radius);
  padding: 0 40px 0;
}

.tours-list__meta li + li {
  margin-left: 50px;
}

.tours-list__meta li a {
  font-size: 12px;
  color: var(--thm-gray);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.tours-list__meta li:hover a {
  color: var(--thm-primary);
}

.tours-list__meta li a i {
  font-size: 13px;
  padding-right: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.tours-list__meta li:hover i {
  color: var(--thm-primary);
}

/*--------------------------------------------------------------
# Tour Details Slider
--------------------------------------------------------------*/
.tour-details-slider .swiper-slide-inner {
  padding-top: 390px;
  padding-bottom: 320px;
}

.tour-details-slider_icon {
  position: absolute;
  bottom: 30px;
  right: 0;
  display: flex;
  align-items: center;
}

.tour-details-slider_icon a {
  width: 38px;
  height: 38px;
  background-color: rgba(var(--thm-base-rgb), 0.2);
  border-radius: 50%;
  text-align: center;
  display: flex;
  font-size: 14px;
  color: #fff;
  z-index: 10;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  align-items: center;
  justify-content: center;
  line-height: 38px;
}

.tour-details-slider_icon a + a {
  margin-left: 10px;
}

.tour-details-slider_icon a:hover {
  background-color: var(--thm-base);
  color: var(--thm-primary);
}

/*--------------------------------------------------------------
# Tour Details
--------------------------------------------------------------*/
.tour-details {
  position: relative;
  display: block;
}

.tour-details__top {
  position: relative;
  display: block;
  background-color: #faf5ee;
  padding: 52px 0 49px;
}

.tour-details__top-inner {
  position: relative;
  display: block;
  overflow: hidden;
}

.tour-details__top-left {
  position: relative;
  display: block;
  float: left;
}

.tour-details__top-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 7px;
}

.tour-details__top-rate {
  font-size: 15px;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details__top-rate span {
  font-size: 20px;
  font-weight: 700;
  color: var(--thm-primary);
}

.tour-details__top-right {
  position: relative;
  display: block;
  float: right;
  padding: 16px 0;
}

.tour-details__top-list {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details__top-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details__top-list li + li {
  margin-left: 38px;
}

.tour-details__top-list li .icon {
  position: relative;
  font-size: 35px;
  color: var(--thm-primary);
  display: flex;
  align-items: center;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.tour-details__top-list li:hover .icon {
  transform: scale(1.2);
}

.tour-details__top-list li .text {
  margin-left: 20px;
}

.tour-details__top-list li .text p {
  font-size: 15px;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  line-height: 21px;
}

.tour-details__top-list li .text h6 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: var(--thm-letter-spacing);
  line-height: 24px;
}

.tour-details__bottom {
  position: relative;
  display: block;
  border-bottom: 1px solid #ebe6de;
  padding: 17px 0;
}

.tour-details__bottom-inner {
  position: relative;
  display: block;
  overflow: hidden;
}

.tour-details__bottom-left {
  position: relative;
  display: block;
  float: left;
}

.tour-details__bottom-list {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details__bottom-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details__bottom-list li:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -40px;
  width: 1px;
  background-color: #ebe6de;
  content: "";
}

.tour-details__bottom-list li:nth-child(1):before {
  display: none;
}

.tour-details__bottom-list li + li {
  margin-left: 90px;
}

.tour-details__bottom-list li .icon {
  font-size: 16px;
  color: var(--thm-primary);
  display: flex;
  align-items: center;
}

.tour-details__bottom-list li .icon i {
  font-size: 13px;
  color: #ffa801;
}

.tour-details__bottom-list li .icon i + i {
  margin-left: 4px;
}

.tour-details__bottom-list li .text {
  margin-left: 10px;
}

.tour-details__bottom-list li .text p {
  font-size: 16px;
  margin: 0;
}

.tour-details__bottom-list li:last-child .text {
  margin-left: 15px;
}

.tour-details__bottom-list li:last-child .text p {
  font-size: 14px;
}

.tour-details__bottom-right {
  position: relative;
  display: block;
  float: right;
}

.tour-details__bottom-right a {
  position: relative;
  display: inline-block;
  background-color: #faf5ee;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: var(--thm-border-radius);
  padding: 0 15px;
  line-height: 30px;
  transition: all 500ms ease;
}

.tour-details__bottom-right a i {
  padding-right: 10px;
}

.tour-details__bottom-right a:hover {
  color: var(--thm-base);
  background-color: var(--thm-primary);
}

.socical {
  display: inline-block;
  position: fixed;
  right: 25px;
  top: 80%;
  width: 45px;
  z-index: 10;
}
.socical a {
  width: 45px;
  height: 45px;
  display: inline-block;
  margin: 3px 0;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.email {
  background: #f7a400 url("https://i.imgur.com/bHKF6wx.png");
}
.zalo {
  background: #0084ff url("https://i.imgur.com/7IAwZX9.png");
}
.messenger {
  background: green url("https://i.imgur.com/xLTR1SL.png");
}

/*--------------------------------------------------------------
# Phone Ring
--------------------------------------------------------------*/
.wrapper{
    left: 0px;
    bottom: 10px;
    display: block;
    position: fixed;
    z-index: 11111111;
}
.coccoc-alo-ph-circle {
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: .1;
  -webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.coccoc-alo-phone {
  background-color: transparent;
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility .5s;
  -moz-transition: visibility .5s;
  -o-transition: visibility .5s;
  transition: visibility .5s;
  right: 150px;
  top: 30px;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  opacity: .75 !important;
}

.coccoc-alo-ph-circle-fill {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: .1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.coccoc-alo-ph-img-circle {
  width: 60px;
  height: 60px;
  top: 70px;
  left: 70px;
  position: absolute;
  background: rgba(30, 30, 30, 0.1) url(https://drive.google.com/uc?id=1V3N2b79QjDWetC_ss9wI3c-xpWDymn9R) no-repeat center center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: .7;
  -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
  background-color: #00aff2;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
  border-color: #00aff2;
  opacity: .5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle {
  border-color: #75eb50;
  opacity: .5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: .75 !important;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-img-circle {
  background-color: #75eb50;
}

@-moz-keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }
  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }
  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }
  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }
  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }
  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }
  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }
  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }
  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@-moz-keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}

@-o-keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }
  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}

/*--------------------------------------------------------------
# Tour Details Two
--------------------------------------------------------------*/
.tour-details-two {
  position: relative;
  display: block;
  padding: 50px 0 120px;
}

.tour-details-two__left {
  position: relative;
  display: block;
}

.tour-details-two__overview {
  position: relative;
  display: block;
}

.tour-details-two__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 18px;
}

.tour-details-two__map iframe {
  position: relative;
  display: block;
  height: 380px;
  width: 100%;
  border-radius: var(--thm-border-radius);
}

.tour-details-two__overview-text {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 31px;
  background: #ffff;
  padding: 23px 17px 24px 40px;
  border: 1px solid #ebe6de;
  border-radius: 8px;
}

.tour-details-two-overview__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 25px;
}

.tour-details-two__overview-bottom {
  position: relative;
  display: block;
  border-bottom: 1px solid #ebe6de;
  padding-bottom: 54px;
  margin-bottom: 50px;
}

.tour-details-two__overview-bottom-inner {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details-two__overview-bottom-left {
  position: relative;
  display: block;
  margin-right: 140px;
}

.tour-details-two__overview-bottom-list {
  position: relative;
  display: block;
}

.tour-details-two__overview-bottom-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details-two__overview-bottom-list li + li {
  margin-top: 6px;
}

.tour-details-two__overview-bottom-list li .icon i {
  color: var(--thm-primary);
  font-size: 13px;
}

.tour-details-two__overview-bottom-list li .text {
  margin-left: 20px;
}

.tour-details-two__overview-bottom-list li .text p {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details-two__overview-bottom-right {
  position: relative;
  display: block;
}

.tour-details-two__overview-bottom-right-list {
  position: relative;
  display: block;
}

.tour-details-two__overview-bottom-right-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details-two__overview-bottom-right-list li + li {
  margin-top: 6px;
}

.tour-details-two__overview-bottom-right-list li .icon i {
  color: var(--thm-black);
  font-size: 15px;
}

.tour-details-two__overview-bottom-right-list li .text {
  margin-left: 20px;
}

.tour-details-two__overview-bottom-right-list li .text p {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details-two__tour-plan {
  position: relative;
  display: block;
}

.tour-details-two__tour-plan .tour-details-two__title {
  margin-bottom: 29px;
}

.faq-one-accrodion .accrodion {
  position: relative;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 0;
  background-color: #faf5ee;
  border: 1px solid transparent;
  border-radius: var(--thm-border-radius);
}

.faq-one-accrodion .accrodion.active {
  border: 1px solid #ebe6de;
  border-radius: var(--thm-border-radius);
}

.faq-one-accrodion .accrodion-title {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 23px 40px 24px;
  padding-right: 27px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  border-bottom: 0px solid var(--thm-extra);
  border-radius: var(--thm-border-radius);
}

.faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--thm-black);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  letter-spacing: var(--thm-letter-spacing);
}

.faq-one-accrodion .accrodion-title h4 span {
  color: var(--thm-primary);
}

.faq-one-accrodion .accrodion + .accrodion {
  margin-top: 20px;
}

.faq-one-accrodion .accrodion.active .accrodion-title {
  background-color: var(--thm-base);
}

.faq-one-accrodion .accrodion.active .accrodion-title h4 {
  color: var(--thm-black);
}

.faq-one-accrodion .accrodion-title h4::before {
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 17px;
  color: var(--thm-black);
  position: absolute;
  top: 50%;
  right: 0;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: "\f107";
  color: var(--thm-primary);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-one-accrodion .accrodion-content {
  position: relative;
  padding: 0px 40px 28px;
  margin-top: -13px;
  border-radius: var(--thm-border-radius);
}

.faq-one-accrodion .accrodion.active .accrodion-content {
  background-color: var(--thm-base);
}

.faq-one-accrodion .accrodion-content p {
  margin: 0;
  font-size: 16px;
  letter-spacing: var(--thm-letter-spacing);
}

.accrodion-content ul {
  position: relative;
  display: block;
  margin-top: 32px;
}

.accrodion-content ul li {
  font-size: 18px;
  color: var(--thm-black);
  font-weight: 500;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details-two__location {
  position: relative;
  display: block;
  margin-top: 50px;
}

.tour-details-two__title {
  margin-bottom: 39px;
}

.tour-details-two__location-map {
  position: relative;
  display: block;
  height: 380px;
  width: 100%;
  border-radius: var(--thm-border-radius);
}

.tour-details-two__related-tours {
  position: relative;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
}

.tour-details-two__related-tours .tour-details-two__title {
  margin-bottom: 29px;
}

.review-scores__title {
  margin-bottom: 29px;
}

.tour-details__review-score {
  border: 1px solid #ebe6de;
  display: -webkit-box;
  display: flex;
  margin-bottom: 40px;
  border-radius: var(--thm-border-radius);
}

.tour-details__review-score-ave {
  -webkit-box-flex: 1;
  flex: 1 1 245px;
  max-width: 245px;
  display: -webkit-box;
  display: flex;
}

.tour-details__review-score-ave .my-auto {
  width: 100%;
  text-align: center;
}

.tour-details__review-score-ave h3 {
  margin: 0;
  font-weight: 400;
  font-size: 100px;
  color: var(--thm-black);
}

.tour-details__review-score-ave p {
  margin: 0;
  color: var(--thm-gray);
  font-size: 20px;
  font-weight: 500;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: var(--thm-letter-spacing);
  line-height: 26px;
}

.tour-details__review-score-ave p i {
  color: #ffa801;
  font-size: 18px;
  margin-right: 10px;
}

.tour-details__review-score__content {
  -webkit-box-flex: 1;
  flex: 1 1 calc(100% - 245px);
  max-width: calc(100% - 245px);
  border-left: 1px solid #ebe6de;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 50px;
}

.tour-details__review-score__bar + .tour-details__review-score__bar {
  margin-top: 8px;
}

.tour-details__review-score__bar-top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.tour-details__review-score__bar-top h3 {
  font-family: var(--thm-font);
  font-size: 16px;
  font-weight: 500;
  color: var(--thm-black);
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details__review-score__bar-top p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--thm-gray);
  letter-spacing: var(--thm-letter-spacing);
  line-height: 32px;
}

.tour-details__review-score__bar-line {
  width: 100%;
  height: 7px;
  background-color: #faf5ee;
  position: relative;
  overflow: hidden;
  border-radius: var(--thm-border-radius);
}

.tour-details__review-score__bar-line span {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--thm-primary);
  border-radius: var(--thm-border-radius);
}

.tour-details__review-comment {
  position: relative;
  display: block;
}

.tour-details__review-comment-single {
  position: relative;
  display: block;
  border-bottom: 1px solid #ebe6de;
  margin-bottom: 60px;
}

.tour-details__review-comment-top {
  position: relative;
  display: flex;
  align-items: center;
}

.tour-details__review-comment-top-img img {
  border-radius: 50%;
}

.tour-details__review-comment-top-content {
  margin-left: 30px;
}

.tour-details__review-comment-top-content h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details__review-comment-top-content p {
  font-size: 16px;
  color: var(--thm-primary);
  line-height: 26px;
  margin: 0;
  font-weight: 500;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details__review-comment-content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--thm-letter-spacing);
  margin-top: 22px;
  margin-bottom: 25px;
}

.tour-details__review-comment-content p {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
  padding-bottom: 19px;
}

.tour-details__review-form-stars {
  margin-bottom: 50px;
}

.tour-details__review-form-stars p {
  display: -webkit-box;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  align-items: center;
}

.tour-details__review-form-stars p + p {
  margin-top: 5px;
}

.tour-details__review-form-stars span {
  font-size: 20px;
  color: var(--thm-gray);
  margin-right: 15px;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details__review-form-stars i {
  color: #faf5ee;
  font-size: 15px;
  line-height: 1em;
  position: relative;
  top: 0px;
}

.tour-details__review-form-stars i + i {
  margin-left: 2px;
}

.tour-details__review-form-stars i.active {
  color: #ffa801;
}

.tour-details__review-form {
  position: relative;
  display: block;
  margin-top: -10px;
}

.tour-details__review-form-left {
  position: relative;
  display: block;
}

.tour-details__review-form-input {
  position: relative;
  display: block;
}

.tour-details__review-form-input input[type="text"],
.tour-details__review-form-input input[type="email"] {
  height: 67px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #faf5ee;
  border-radius: var(--thm-border-radius);
  margin-bottom: 20px;
  padding: 0 30px;
  font-size: 13px;
  color: var(--thm-gray);
  font-weight: 500;
}

.tour-details__review-form-rate {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: -20px;
}

.tour-details__review-form-rate-single {
  position: relative;
  display: block;
  overflow: hidden;
}

.tour-details__review-form-rate-single
  + .tour-details__review-form-rate-single {
  margin-top: 14px;
}

.tour-details__review-form-rate-left {
  position: relative;
  display: block;
  float: left;
}

.tour-details__review-form-rate-left span {
  font-size: 20px;
  color: var(--thm-gray);
  margin-right: 15px;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details__review-form-rate-right {
  position: relative;
  display: block;
  float: right;
}

.tour-details__review-form-rate-right i {
  color: #faf5ee;
  font-size: 15px;
  line-height: 1em;
  position: relative;
  top: 0px;
}

.tour-details__review-form-rate-right i + i {
  margin-left: 2px;
}

.tour-details__review-form-rate-right i.active {
  color: #ffa801;
}

.tour-details__review-form-textarea {
  position: relative;
  display: block;
  overflow: hidden;
}

.tour-details__review-form-textarea textarea {
  height: 185px;
  width: 100%;
  border: none;
  background-color: #faf5ee;
  outline: none;
  border-radius: var(--thm-border-radius);
  color: var(--thm-gray);
  font-size: 13px;
  font-weight: 500;
  padding: 25px 30px 30px;
}

.tour-details__review-form-btn {
  margin-top: 20px;
  font-size: 15px;
  padding: 17px 60px 17px;
}

.tour-details-two__sidebar {
  position: relative;
  display: block;
  margin-top: 8px;
}

.tour-details-two__sidebar-display{
  display: none;
}

.tour-details-two__book-tours {
  position: relative;
  display: block;
  background-color: #faf5ee;
  padding: 25px;
  border-radius: var(--thm-border-radius);
}



.tour-details-two__sidebar-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 22px;
}

.tour-details-two__sidebar-form-input {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.tour-details-two__sidebar-form-input input[type="text"] {
  height: 67px;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 30px;
  font-size: 13px;
  font-weight: 500;
  color: var(--thm-gray);
  border-radius: var(--thm-border-radius);
  background-color: var(--thm-base);
}
.tour-details-two__sidebar-form-input input[type="email"] {
  height: 67px;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 30px;
  font-size: 13px;
  font-weight: 500;
  color: var(--thm-gray);
  border-radius: var(--thm-border-radius);
  background-color: var(--thm-base);
}
.tour-details-two__sidebar-form-input textarea{
  height: 67px;
  width: 100%;
  border: none;
  outline: none;
  padding: 25px 30px;
  font-size: 13px;
  font-weight: 500;
  color: var(--thm-gray);
  border-radius: var(--thm-border-radius);
  background-color: var(--thm-base);
}

.tour-details-two__sidebar-form-input
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.tour-details-two__sidebar-form-input .bootstrap-select > .dropdown-toggle {
  background-color: var(--thm-base);
  border: none;
  font-weight: 500;
  border-radius: var(--thm-border-radius);
  padding: 0 30px;
  font-size: 13px;
  color: var(--thm-gray);
  display: block;
  height: 67px;
  line-height: 67px;
  background-repeat: no-repeat;
}

.tour-details-two__sidebar-form-input
  .bootstrap-select
  > .dropdown-toggle::after {
  display: none;
}

.tour-details-two__sidebar-form-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  line-height: 67px;
  font-size: 16px;
}

.tour-details-two__sidebar-form-icon i {
  color: var(--thm-gray);
  font-size: 14px;
}

.tour-details-two__sidebar-btn {
  font-size: 15px;
  padding: 17px 87px 17px;
  text-align: center;
  width: 100%;
}

.react-datepicker {
  background: #fff none repeat scroll 0 0;
  border: 1px solid var(--thm-black);
  color: #252525;
  font-size: 14px;
  border-radius: var(--thm-border-radius);
  padding: 5px;
  font-family: var(--thm-font);
  width: 270px;
}

.react-datepicker .react-datepicker__month-container {
  width: 100%;
  height: 100%;
}

.react-datepicker .react-datepicker__triangle {
  display: none;
}

.react-datepicker .react-datepicker__current-month {
  background: var(--thm-primary) none repeat scroll 0 0;
  border: 2px solid var(--thm-primary);
  border-radius: var(--thm-border-radius);
  color: var(--thm-base);
  font-weight: 700;
  position: relative;
}

.react-datepicker .react-datepicker__day {
  color: #000000 !important;
  text-align: center;
  background: #f5f5f5 !important;
  line-height: 2.5rem;
  margin: 0.1rem;
  width: 2.1rem;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--today {
  background: #333333 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.react-datepicker .react-datepicker__navigation--previous {
  left: 8px;
}

.react-datepicker .react-datepicker__navigation-icon--previous {
  right: 1px;
}

.react-datepicker .react-datepicker__navigation-icon--next {
  left: 1px;
}

.react-datepicker .react-datepicker__navigation--previous,
.react-datepicker .react-datepicker__navigation--next {
  border-radius: 30%;
  height: 20px;
  position: absolute;
  top: 15px;
  width: 20px;
  transition: all 500ms ease;
  cursor: pointer;
}

.react-datepicker .react-datepicker__navigation--next {
  right: 8px;
}

.react-datepicker .react-datepicker__navigation--previous:hover,
.react-datepicker .react-datepicker__navigation--next:hover {
  top: 15px;
  background: #fff;
  border: none;
}

.react-datepicker .react-datepicker__navigation-icon {
  top: 7px;
}

.react-datepicker .react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker .react-datepicker__month {
  font-size: 13px;
  margin: 0 0 0.4em;
  width: 100%;
}

.react-datepicker .react-datepicker__day-names {
  font-size: 13px;
  width: 100%;
  border: none;
}

.react-datepicker .react-datepicker__day-name {
  font-weight: 600;
  padding: 2px 3px;
  text-align: center;
  width: 2rem;
}

.react-datepicker .react-datepicker__header {
  border: none;
  background-color: white;
  padding: 2px 0 8px 0;
}

.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-state-default:active {
  background: #43c3ea;
  color: #fff;
  border-color: #43c3ea;
}

#ptTimeSelectCntr .ui-widget {
  font-family: var(--thm-font);
}

.dataResult{
  position: absolute;
    top: 65px;
    width: 100%;
    height: 200px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow-y: auto;
}
.dataResult .dataItem {
  width: 100%;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
  padding: 15px 25px;
}

.tour-details-two__last-minute {
  position: relative;
  display: block;
  border: 1px solid #ebe6de;
  border-radius: var(--thm-border-radius);
  padding: 25px;
  margin-top: 30px;
}
.tour-details-two__last-minute img {
  width: 60px;
  height: 65px;
  object-fit: cover;
}
.mr-3{
  margin-right: 15px;
}

.tour-details-two__last-minute .tour-details-two__sidebar-title {
  margin-bottom: 18px;
}

.tour-details-two__last-minute-list li {
  position: relative;
  border-bottom: 1px solid #ebe6de;
  padding-bottom: 25px;
  margin-bottom: 26px;
  padding-left: 80px;
}

.tour-details-two__last-minute-list li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.tour-details-two__last-minute-image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: var(--thm-border-radius);
}

.tour-details-two__last-minute-image:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(var(--thm-black-rgb), 0.5);
  border-radius: var(--thm-border-radius);
  transform: scaleX(0);
  transform-origin: center;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.tour-details-two__last-minute-list
  li:hover
  .tour-details-two__last-minute-image:before {
  transform: scaleX(1);
}

.tour-details-two__last-minute-image img {
  border-radius: var(--thm-border-radius);
  width: 60px;
  height: 65px;
  object-fit: cover;
}

.tour-details-two__last-minute-content {
  position: relative;
  top: -6px;
}

.tour-details-two__last-minute-content h6 {
  font-size: 14px;
  font-weight: 700;
  color: var(--thm-primary);
  line-height: 24px;
}

.tour-details-two__last-minute-content h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: var(--thm-letter-spacing);
}

.tour-details-two__last-minute-content p {
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 22px;
}

/*--------------------------------------------------------------
# Destinations Details
--------------------------------------------------------------*/
.destinations-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.destinations-details__left {
  position: relative;
  display: block;
}

.destinations-details__img {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.destinations-details__img img {
  width: 100%;
  border-radius: var(--thm-border-radius);
}

.destinations-details__discover {
  position: relative;
  display: block;
  margin-bottom: 33px;
}

.destinations-details__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: var(--thm-letter-spacing);
  margin-bottom: 18px;
}

.destinations-details__discover-text-1 {
  font-size: 16px;
  margin: 0;
  padding-bottom: 30px;
}

.destinations-details__discover-text-2 {
  font-size: 16px;
  margin: 0;
}

.destinations-details__overview {
  position: relative;
  display: block;
}

.destinations-details__overview .destinations-details__title {
  font-size: 30px;
  margin-bottom: 0;
}

.destinations-details__overview-list {
  position: relative;
  display: block;
  margin-top: -16px;
}

.destinations-details__overview-list li {
  position: relative;
  border-bottom: 1px solid #ebe6de;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 19px;
}

.destinations-details__overview-list li:last-child {
  border-bottom: 0;
}

.destinations-details__overview-left {
  position: relative;
  display: block;
  max-width: 155px;
  width: 100%;
  float: left;
}

.destinations-details__overview-left > p {
  font-weight: 500;
  color: var(--thm-black);
  letter-spacing: var(--thm-letter-spacing);
  margin: 0;
}

.destinations-details__overview-right {
  position: relative;
  display: block;
  max-width: 245px;
  float: left;
  margin-left: 150px;
}

.destinations-details__overview-right > p {
  color: var(--thm-primary);
  font-weight: 500;
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.destinations-details__faq {
  position: relative;
  display: block;
  margin-top: 30px;
}

.destinations-details__faq .faq-one-accrodion .accrodion-content {
  padding: 0px 40px 18px;
}

.destinations-details__right {
  position: relative;
  display: block;
}

.tour-details-two__last-minute-2 {
  margin-top: 0;
}

.destinations-details__discount {
  position: relative;
  display: block;
  margin-top: 30px;
  z-index: 1;
}

.destinations-details__discount img {
  width: 100%;
  border-radius: var(--thm-border-radius);
}

.destinations-details__discount-content {
  position: absolute;
  bottom: 44px;
  left: 50px;
  z-index: 3;
}

.destinations-details__discount-content h2 {
  font-size: 50px;
  color: var(--thm-base);
  font-weight: 400;
  font-family: var(--thm-reey-font);
}

.destinations-details__discount-content h4 {
  font-size: 24px;
  font-weight: 700;
  color: var(--thm-base);
  line-height: 29px;
  letter-spacing: var(--thm-letter-spacing);
  margin-top: -7px;
}

/*Destinations Page*/
.destinations-page {
  padding-bottom: 110px;
}

/*--------------------------------------------------------------
# About Page
--------------------------------------------------------------*/
.about-page {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.about-page__left {
  position: relative;
  display: block;
  margin-right: 50px;
}

.about-page__img {
  position: relative;
  display: block;
  overflow: hidden;
}

.about-page__img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 10;
}

.about-page__img:hover:before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}

.about-page__img img {
  width: 100%;
  border-radius: var(--thm-border-radius);
}

.about-page__right {
  position: relative;
  display: block;
}

.about-page__right .section-title {
  margin-top: 10px;
  margin-bottom: 27px;
}

.about-page__text-1 {
  font-size: 30px;
  font-weight: 700;
  color: var(--thm-primary);
  line-height: 40px;
  letter-spacing: var(--thm-letter-spacing);
  margin: 0;
  padding-bottom: 24px;
}

.about-page__text-2 {
  margin: 0;
  letter-spacing: var(--thm-letter-spacing);
}

.about-page__progress {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 31px;
}

.about-page__progress-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--thm-black);
  margin-bottom: 6px;
  letter-spacing: var(--thm-letter-spacing);
}

.about-page__progress .bar {
  position: relative;
  width: 100%;
  height: 15px;
  background: #faf5ee;
  border-radius: 30px;
  margin-bottom: 22px;
}

.about-page__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 15px;
  border-radius: 30px;
  background: var(--thm-primary);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.about-page__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--thm-gray);
  line-height: 26px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-page__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.about-page__progress .bar.marb-0 {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About Page Testimonial
--------------------------------------------------------------*/
.about-page-testimonial {
  padding-bottom: 114px;
  z-index: 1;
}

.about-page-testimonial:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 768px;
  content: "";
  background-color: #faf5ee;
  z-index: -1;
}

.about-page-testimonial-map {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: center;
  height: 560px;
  width: 1157px;
  opacity: 0.6;
  z-index: -1;
}

/*--------------------------------------------------------------
# Team One
--------------------------------------------------------------*/
.team-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.team-one-map {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: center;
  height: 722px;
  width: 1491px;
  opacity: 0.7;
  z-index: -1;
}

.team-one__single {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.team-one__img {
  position: relative;
  display: block;
  border-radius: var(--thm-border-radius);
  overflow: hidden;
}

.team-one__img:before {
  position: absolute;
  content: "";
  background-color: rgba(var(--thm-black-rgb), 0.3);
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  transition: all 500ms ease;
}

.team-one__img:after {
  position: absolute;
  content: "";
  background-color: rgba(var(--thm-black-rgb), 0.3);
  width: 0%;
  height: 100%;
  right: 0px;
  top: 0px;
  transition: all 500ms ease;
}

.team-one__single:hover .team-one__img::before,
.team-one__single:hover .team-one__img::after {
  width: 50%;
}

.team-one__img img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  border-radius: var(--thm-border-radius);
  transform: scale(1);
  transition: all 500ms ease;
}

.team-one__single:hover .team-one__img img {
  transform: scale(1.05);
}

.team-one__content {
  position: relative;
  display: block;
  padding-top: 32px;
  border-radius: var(--thm-border-radius);
  margin-top: -20px;
  background-color: var(--thm-base);
  margin-left: 10px;
  margin-right: 10px;
  z-index: 2;
}

.team-one__name {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--thm-letter-spacing);
}

.team-one__title {
  font-size: 12px;
  color: var(--thm-primary);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 22px;
}

.team-one__social {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  transform: scaleY(0);
  transform-origin: center;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  transform-origin: bottom center;
}

.team-one__single:hover .team-one__social {
  transform: scaleY(1);
}

.team-one__social a {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--thm-black);
  background-color: #faf5ee;
  border-radius: 50%;
  font-size: 15px;
  height: 50px;
  width: 50px;
  transform: rotate(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.team-one__social a:hover {
  color: var(--thm-base);
  transform: rotate(360deg);
}

.team-one__social a:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--thm-primary);
  border-radius: 50%;
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.team-one__social a:hover:before {
  transform: scaleX(1);
}

.team-one__social a + a {
  margin-left: 10px;
}

/*Brand Three*/
.brand-three {
  border-bottom: 0;
  border-top: 1px solid #ebe6de;
}
