// @import '~bootstrap/dist/css/bootstrap.min.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../public/scss/main";
.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 28px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background:#4572d9;
  color: #fff;
  z-index: 100;
  @media (max-width: 792px) {
    bottom: 50px;
    right: 5px;
  }
}
.scroll-to-top:hover {
  background: #4572d9!important;
}
.scroll-to-top .icon {
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease;
}
.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pagination-container {
  display: flex;
  list-style-type: none;
  padding: 25px 0px;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    justify-content:center;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: #4572d9;
      cursor: pointer;
    }

    &.selected {
      background-color: #4572d9;
      color: #fff;
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}