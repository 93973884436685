
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.pr-3{
 padding-right: 15px !important;
}
.pl-3{
  padding-left: 15px !important;
}
.text-left{
  text-align: left;
}
.mr-1{
  margin-right: 4px;
}
.mtup-5px{
  margin-top: -3px;
}
.cursor-pointer{
  cursor: pointer;
}
.shop-footer{
    color: #899dab;
    font-weight: 500;
    cursor: pointer;
}
.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.ql-snow .ql-picker{
  height: auto !important;
}

