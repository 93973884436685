html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.cl-495{
    color: #495057;
}

/* sign in page */
.sign-in-page{
    margin-top: 80px;
    margin-bottom: 80px;
    padding-top: 60px;
}
.sign-in-page i{
   color: #e8604c
}
.sign-in-page .form-sign-in{
    padding: 15px
}
.sign-in-page .sign-in-page-text-header h5{
        color: #8089e8;
        font-size: 18px;
        line-height: 34px;
        font-weight: 500;
}
.sign-in-page .sign-in-page-text-header p{
    color: #8089e8;
    font-size: 16px;
    line-height: 34px;
    font-weight: 400;
}
.sign-in-page .form-sign-in{
    padding: 15px
}
.sign-in-page .form-sign-in label{
    color: #495057;
    font-size: 16px;
    line-height: 34px;
    font-weight: 500;
}
.sign-in-page .form-sign-in input{
    display: block;
    width: 100%;
    padding: 15px;
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffff !important;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sign-in-page .form-sign-in p{
    margin-bottom: 16px;
    color: #EC0000;
    font-size: 14px;
    line-height: 34px;
    font-weight: 400;
}
.bg-soft {
    background-color: rgba(85, 110, 230, 0.25) !important;
}
.d-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
